import React, { Component } from "react";
import "./Contestant.scss";
import PropTypes from "prop-types";
import {
  commafy,
  getProperLanguage,
  stretchNumber,
} from "../utilities/Utilities";
import FadeLoadImage from "./FadeLoadImage";
import { DefaultPageant } from "../configs/Default";
import ReactGA from "react-ga4";
import Crown from "./Crown";

class Contestant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {}

  translate(text) {
    if (this.props.language === "zh") {
      return text;
    } else {
      return getProperLanguage(text, true);
    }
  }

  render() {
    const { pageant, pageantNaming } = this.props;
    return (
      <div className="contestant-wrapper" id={`${pageantNaming}-${pageant.id}`}>
        {!this.props.liveVoteMode ? <Crown pageant={pageant} /> : null}
        {!this.props.liveVoteMode ? (
          <div className="contestant-vote-info">
            <span>{this.translate("目前得票")}</span>
            <span className="vote-count">{commafy(pageant.vote)}</span>
            <span>{this.translate(" 張")}</span>
          </div>
        ) : null}
        <div className="contestant-profile-picture-display">
          <div>
            <FadeLoadImage
              preloadThumb={pageant.profile_picture_b64}
              fullSize={pageant.profile_picture}
              class="tone-up hover-zoom"
              style={{
                objectFit: "cover",
                objectPosition: "top",
                borderRadius: 20,
              }}
              wrapperStyle={{
                justifyContent: "flex-start",
                borderRadius: 20,
                background:
                  "linear-gradient(167deg, #dcac60 0%, #8e3798 50%, #358fe1 100%)",
              }}
            />
            <div style={{ height: 64 }}></div>
          </div>
          <div className="contestant-id">{stretchNumber(pageant.id, 2)}</div>
          <div
            className={`contestant-basic-details ${
              this.props.language === "zh" ? "" : "sans-sc"
            }`}
          >
            <p>
              {pageant.name_cn}{" "}
              <span className="nickname">
                <i>{this.translate(pageant.nickname)}</i>
              </span>
            </p>
            <p className="name-en">{pageant.name_en}</p>
            <p className={`normal first ${!this.state.expanded && "hide"}`}>
              {this.translate(`${pageant.age}歲，來自${pageant.origin}`)}
            </p>

            <p className={`normal ${!this.state.expanded && "hide"}`}>
              {this.translate(`身高：${pageant.height}cm`)}
            </p>

            <p className={`normal ${!this.state.expanded && "hide"}`}>
              {this.translate(`體重：${pageant.weight}KG`)}
            </p>

            <p className={`normal ${!this.state.expanded && "hide"}`}>
              {this.translate(`職業：${pageant.occupation}`)}
            </p>
            {pageantNaming === "#pageant-miss" && (
              <p className={`normal ${!this.state.expanded && "hide"}`}>
                {this.translate(`三圍：${pageant.body}`)}
              </p>
            )}
            {/* <a
              className="normal bold url ig"
              href={`https://instagram.com/${pageant.ig}`}
              onClick={() => {
                ReactGA.event({
                  category: "ig_click",
                  action: "pageant",
                  label: pageant.name_cn,
                });
              }}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={InstagramIcon}
                alt="instagram"
                className="ig-icon"
                loading="lazy"
              />
              {pageant.ig}
            </a> */}
            {this.state.expanded ? (
              <div
                className="contestant-details-close-btn"
                onClick={() => {
                  this.setState({
                    expanded: !this.state.expanded,
                  });
                  ReactGA.event({
                    category: "close_details",
                    action: "pageant",
                    label: pageant.name_cn,
                  });
                }}
              >
                <svg
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="15" cy="15" r="15" fill="#F8F6F4" />
                  <path
                    d="M20.1047 10.0315L9.97035 20.1659"
                    stroke="#dcac60"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.96924 10.0308L20.1036 20.1651"
                    stroke="#dcac60"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            ) : (
              <div
                className="contestant-details-view-btn"
                onClick={() => {
                  this.setState({
                    expanded: !this.state.expanded,
                  });
                  ReactGA.event({
                    category: "view_details",
                    action: "pageant",
                    label: pageant.name_cn,
                  });
                }}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.58736 3.43022H12.4478M12.4478 3.43022V10.2907M12.4478 3.43022L3.96252 11.9155"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        <button
          onClick={this.props.showPageantCallback}
          className={`contestant-vote-btn ${
            this.props.language === "zh" ? "" : "sc"
          }`}
        >
          {pageantNaming === "#pageant-miss"
            ? this.translate("給她投票")
            : this.translate("給他投票")}
        </button>
      </div>
    );
  }
}

Contestant.propTypes = {
  pageant: PropTypes.object,
  showPageantCallback: PropTypes.func,
};

Contestant.defaultProps = {
  pageant: DefaultPageant,
  showPageantCallback: () => {},
};

export default Contestant;
