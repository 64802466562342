import "./DialogBox.scss";
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { HideDialog } from "../utilities/EventBus";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  render() {
    return (
      <Dialog
        open={this.props.dialogPack.showDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          HideDialog();
        }}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{this.props.dialogPack.dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {this.props.dialogPack.dialogMessage}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {this.props.dialogPack.dialogShowErrorCode
              ? `Error Code: ${this.props.dialogPack.dialogErrorCode}`
              : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {this.props.dialogPack.dialogShowSecondaryButton && (
            <Button
              // variant="outlined"
              color="secondary"
              onClick={this.props.dialogPack.dialogSecondaryButtonCallback}>
              {this.props.dialogPack.dialogSecondaryButtonLabel}
            </Button>
          )}
          {this.props.dialogPack.dialogShowPrimaryButton && (
            <Button
              color="secondary"
              onClick={this.props.dialogPack.dialogPrimaryButtonCallback}>
              {this.props.dialogPack.dialogPrimaryButtonLabel}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default DialogBox;
