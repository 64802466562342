import packageJson from "../../package.json";

export const DefaultVoteInfo = {
  vote_point_cost: 0,
  free_vote: 0,
  free_vote_left: 0,
  vote_history: [],
  vote_progress: [],
};

export const DefaultPointHistory = {
  point: 0,
  point_history: [],
};

export const DefaultPageant = {
  id: 0,
  rank: 0,
  name_cn: "",
  name_en: "",
  age: 0,
  height: 0,
  weight: 0,
  occupation: "",
  ig: "",
  profile_picture_b64: "",
  profile_picture: "",
  origin: "",
  vote: 0,
};
export const LoginPageURL =
  "https://atvnewsonline.com/wp-login.php?redirect_to=https://atvnewsonline.com/" +
  packageJson.homepage;

export const countryCodes = [
  "1", // United States and Canada
  "7", // Russia and Kazakhstan
  "20", // Egypt
  "27", // South Africa
  "30", // Greece
  "31", // Netherlands
  "32", // Belgium
  "33", // France
  "34", // Spain
  "36", // Hungary
  "39", // Italy
  "40", // Romania
  "41", // Switzerland
  "43", // Austria
  "44", // United Kingdom
  "45", // Denmark
  "46", // Sweden
  "47", // Norway
  "48", // Poland
  "49", // Germany
  "51", // Peru
  "52", // Mexico
  "53", // Cuba
  "54", // Argentina
  "55", // Brazil
  "56", // Chile
  "57", // Colombia
  "58", // Venezuela
  "60", // Malaysia
  "61", // Australia
  "62", // Indonesia
  "63", // Philippines
  "64", // New Zealand
  "65", // Singapore
  "66", // Thailand
  "81", // Japan
  "82", // South Korea
  "84", // Vietnam
  "86", // China
  "90", // Turkey
  "91", // India
  "92", // Pakistan
  "93", // Afghanistan
  "94", // Sri Lanka
  "95", // Myanmar (Burma)
  "98", // Iran
  "212", // Morocco
  "213", // Algeria
  "216", // Tunisia
  "218", // Libya
  "220", // Gambia
  "221", // Senegal
  "222", // Mauritania
  "223", // Mali
  "224", // Guinea
  "225", // Ivory Coast
  "226", // Burkina Faso
  "227", // Niger
  "228", // Togo
  "229", // Benin
  "230", // Mauritius
  "231", // Liberia
  "232", // Sierra Leone
  "233", // Ghana
  "234", // Nigeria
  "235", // Chad
  "236", // Central African Republic
  "237", // Cameroon
  "238", // Cape Verde
  "239", // Sao Tome and Principe
  "240", // Equatorial Guinea
  "241", // Gabon
  "242", // Republic of the Congo
  "243", // Democratic Republic of the Congo (formerly Zaire)
  "244", // Angola
  "245", // Guinea-Bissau
  "246", // British Indian Ocean Territory
  "247", // Ascension Island
  "248", // Seychelles
  "249", // Sudan
  "250", // Rwanda
  "251", // Ethiopia
  "252", // Somalia
  "253", // Djibouti
  "254", // Kenya
  "255", // Tanzania
  "256", // Uganda
  "257", // Burundi
  "258", // Mozambique
  "260", // Zambia
  "261", // Madagascar
  "262", // Reunion and Mayotte (France)
  "263", // Zimbabwe
  "264", // Namibia
  "265", // Malawi
  "266", // Lesotho
  "267", // Botswana
  "268", // Swaziland
  "269", // Comoros
  "290", // Saint Helena
  "291", // Eritrea
  "297", // Aruba (Netherlands)
  "298", // Faroe Islands (Denmark)
  "299", // Greenland (Denmark)
  "350", // Gibraltar (United Kingdom)
  "351", // Portugal
  "352", // Luxembourg
  "353", // Ireland
  "354", // Iceland
  "355", // Albania
  "356", // Malta
  "357", // Cyprus
  "358", // Finland
  "359", // Bulgaria
  "370", // Lithuania
  "371", // Latvia
  "372", // Estonia
  "373", // Moldova
  "374", // Armenia
  "375", // Belarus
  "376", // Andorra
  "377", // Monaco
  "378", // San Marino
  "379", // Vatican City
  "380", // Ukraine
  "381", // Serbia
  "382", // Montenegro
  "383", // Kosovo
  "385", // Croatia
  "386", // Slovenia
  "387", // Bosnia and Herzegovina
  "389", // North Macedonia
  "420", // Czech Republic
  "421", // Slovakia
  "423", // Liechtenstein
  "500", // Falkland Islands
  "501", // Belize
  "502", // Guatemala
  "503", // El Salvador
  "504", // Honduras
  "505", // Nicaragua
  "506", // Costa Rica
  "507", // Panama
  "508", // Saint Pierre and Miquelon (France)
  "509", // Haiti
  "590", // Guadeloupe and Martinique (France)
  "591", // Bolivia
  "592", // Guyana
  "593", // Ecuador
  "594", // French Guiana (France)
  "595", // Paraguay
  "596", // French Antilles (France)
  "597", // Suriname
  "598", // Uruguay
  "599", // Caribbean Netherlands (Netherlands)
  "670", // Timor-Leste
  "672", // Australian Antarctic Territory
  "673", // Brunei
  "674", // Nauru
  "675", // Papua New Guinea
  "676", // Tonga
  "677", // Solomon Islands
  "678", // Vanuatu
  "679", // Fiji
  "680", // Palau
  "681", // Wallis and Futuna (France)
  "682", // Cook Islands (New Zealand)
  "683", // Niue (New Zealand)
  "684", // American Samoa (United States)
  "685", // Samoa
  "686", // Kiribati
  "687", // New Caledonia (France)
  "688", // Tuvalu
  "689", // French Polynesia (France)
  "690", // Tokelau (New Zealand)
  "691", // Federated States of Micronesia
  "692", // Marshall Islands
  "850", // North Korea
  "852", // Hong Kong (China)
  "853", // Macao (China)
  "855", // Cambodia
  "856", // Laos
  "880", // Bangladesh
  "886", // Taiwan
  "960", // Maldives
  "961", // Lebanon
  "962", // Jordan
  "963", // Syria
  "964", // Iraq
  "965", // Kuwait
  "966", // Saudi Arabia
  "967", // Yemen
  "968", // Oman
  "970", // Palestine
  "971", // United Arab Emirates
  "972", // Israel
  "973", // Bahrain
  "974", // Qatar
  "975", // Bhutan
  "976", // Mongolia
  "977", // Nepal
  "992", // Tajikistan
  "993", // Turkmenistan
  "994", // Azerbaijan
  "995", // Georgia
  "996", // Kyrgyzstan
  "998", // Uzbekistan
  // Add more country codes as needed
];
