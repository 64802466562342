import Pageant1B64 from "../assets/images/base64/pageant-01";
import Pageant1Tiny from "../assets/images/small/pageant-01.png";
import Pageant2B64 from "../assets/images/base64/pageant-02";
import Pageant2Tiny from "../assets/images/small/pageant-02.png";
import Pageant3B64 from "../assets/images/base64/pageant-03";
import Pageant3Tiny from "../assets/images/small/pageant-03.png";
import Pageant5B64 from "../assets/images/base64/pageant-05";
import Pageant5Tiny from "../assets/images/small/pageant-05.png";
import Pageant12B64 from "../assets/images/base64/pageant-12";
import Pageant12Tiny from "../assets/images/small/pageant-12.png";
import Pageant13B64 from "../assets/images/base64/pageant-13";
import Pageant13Tiny from "../assets/images/small/pageant-13.png";
import Pageant14B64 from "../assets/images/base64/pageant-14";
import Pageant14Tiny from "../assets/images/small/pageant-14.png";
import Pageant16B64 from "../assets/images/base64/pageant-16";
import Pageant16Tiny from "../assets/images/small/pageant-16.png";
import Pageant20B64 from "../assets/images/base64/pageant-20";
import Pageant20Tiny from "../assets/images/small/pageant-20.png";
import Pageant21B64 from "../assets/images/base64/pageant-21";
import Pageant21Tiny from "../assets/images/small/pageant-21.png";
import Pageant23B64 from "../assets/images/base64/pageant-23";
import Pageant23Tiny from "../assets/images/small/pageant-23.png";
import Pageant24B64 from "../assets/images/base64/pageant-24";
import Pageant24Tiny from "../assets/images/small/pageant-24.png";
import Pageant25B64 from "../assets/images/base64/pageant-25";
import Pageant25Tiny from "../assets/images/small/pageant-25.png";
import Pageant26B64 from "../assets/images/base64/pageant-26";
import Pageant26Tiny from "../assets/images/small/pageant-26.png";
import Pageant27B64 from "../assets/images/base64/pageant-27";
import Pageant27Tiny from "../assets/images/small/pageant-27.png";

import NewPageant1 from "../assets/images/finalists/finalists_1.jpg";
import NewPageant2 from "../assets/images/finalists/finalists_2.jpg";
import NewPageant3 from "../assets/images/finalists/finalists_3.jpg";
import NewPageant4 from "../assets/images/finalists/finalists_4.jpg";
import NewPageant5 from "../assets/images/finalists/finalists_5.jpg";
import NewPageant6 from "../assets/images/finalists/finalists_6.jpg";
import NewPageant7 from "../assets/images/finalists/finalists_7.jpg";
import NewPageant8 from "../assets/images/finalists/finalists_8.jpg";
import NewPageant9 from "../assets/images/finalists/finalists_9.jpg";
import NewPageant10 from "../assets/images/finalists/finalists_10.jpg";
import NewPageant11 from "../assets/images/finalists/finalists_11.jpg";
import NewPageant12 from "../assets/images/finalists/finalists_12.jpg";
import NewPageant13 from "../assets/images/finalists/finalists_13.jpg";
import NewPageant14 from "../assets/images/finalists/finalists_14.jpg";
import NewPageant15 from "../assets/images/finalists/finalists_15.jpg";

// import Pageant28B64 from "../assets/images/base64/pageant-28";
// import Pageant28Full from "../assets/images/large/pageant-28.png";
// import { randomBetween } from "../utilities/Utilities";

// CSV data

// 編號,中文姓名,暱稱,英文姓名（身份證/護照）,年齡,身高（公分）,體重（公斤）,三圍（寸）,職業,来自（州）,Instagram
// 1,邱心儿,,Kew Xin Er,18,159,38.25,15.12993948,學生,吉隆坡,https://www.instagram.com/xinerr.x/
// 2,林靖欣,Ashley,Ashley Lim Jinq Xin,28,159,43.5,17.20659784,油管博主,雪兰莪,https://www.instagram.com/ashleylimjinq/
// 3,池玲德,Reina,Rinachelle Dieh Ling Teck,23,163,47.25,17.78388347,Social media influencer,沙巴,https://www.instagram.com/reina_nana1014/
// 4,許佩雯,peiwen,Khoo Pei Wen,28,164,44.85,16.67534206,內容創作者,檳城,https://www.instagram.com/peiween/
// 5,雷欣蕙,Luwee,Luwe Xin Hui,23,158,47.9,19.18763019,Social media influencer,马六甲,https://www.instagram.com/luweexxi/
// 6,陸婉琳,Stephy,Loke Wan Lin,29,158,43.6,17.46514982,室內設計師/花藝師,雪蘭莪,https://www.instagram.com/stephylokee/
// 7,徐靖儀,Jean,Chee Jing Yee,24,166,58.45,21.21135143,營銷主管,柔佛,https://www.instagram.com/jean.s.yc/
// 8,潘思欣,,Pua Sze Sin,23,164,46.2,17.17727543,內容創作者,雪蘭莪,https://www.instagram.com/szesin.p/
// 9,胡菀玲,,Regina Woo Wan Ling,23,162,48.75,18.57567444,學生,雪蘭莪,https://www.instagram.com/regina_121413/
// 10,陳琬晴,Wendy,Tan Wan Qing,23,159,43.55,17.22637554,珠寶設計師（電商）,檳城,https://www.instagram.com/_wanqing_/
// 11,鄭嘉萱,carol,Chang Kar Syuen,19,173,49.1,16.405493,學生,霹雳,https://www.instagram.com/carol_changgg/
// 12,黃欣琳,Serene,Ng Xin Lin,25,169,44.7,15.65071251,秘書,柔佛,https://www.instagram.com/serene_nxl/
// 13,翁惠琳,Lyla,Ung Hei Ling,24,172,53.65,18.1348026,美睫師,砂拉越,https://www.instagram.com/lyla.ung/
// 14,黃美琪,,Yoshiko Wong Mei Chi,26,160,41.05,16.03515625,內容創作者,吉隆坡,https://www.instagram.com/yoyostrawberrychichi/
// 15,黃靜儀,Laraine,Ng Jiin Yi,26,163,44.55,16.76766156,Social media influencer,吉隆坡,https://www.instagram.com/laraine.ocean/
// 16,葉惠瑩,Hayley Yeap,Yeap Hui yin,26,168,48.05,17.02451814,空姐,霹雳,https://www.instagram.com/hayleyhuiyin/
// 17,傅雪玲,,Shelyn Poh,24,174,53.8,17.76985071,婚宴主持人,彭亨,https://www.instagram.com/shelynpoh/
// 18,鄧佳欣,,Thean Jia Sin,29,172,52.45,17.72917793,模特兒/演員,柔佛,https://www.instagram.com/jiasin_thean/
// 19,林可欣,Hayley,Lim Hor-Yen,23,165,57.5,21.12029385,股票經紀人,雪蘭莪,https://www.instagram.com/hayleylimmm/
// 20,陳湘維,Nicole,Tan Siang Wei,22,158,46.2,18.50664958,特殊兒童治療師/學生,雪蘭莪,https://www.instagram.com/nicaboo.t/
// 21,黃詩淋,Carmen,Wong Si Mun,24,163,42.8,16.10899921,銷售主管,霹雳,https://www.instagram.com/carmenwg_/
// 22,吳紫頤,,Goh Zhe Ee,22,171,50.1,17.13347697,電商,马六甲,https://www.instagram.com/zy_zhe/
// 23,李茹瑩,Joveen,Lee Joveen,23,165,53.9,19.7979798,學生,檳城,https://www.instagram.com/__j.veen/
// 24,周詩穎,Seocy,Chew Sei ing,28,167,49.55,17.76686149,自僱人士,柔佛,https://www.instagram.com/seocychew/
// 25,陳美詩,Maisy,Tan Mei See,28,164,50.75,18.8689768,烘焙師,霹雳,https://www.instagram.com/maisy317/
// 26,李靖雯,Joey,Joey Lee Cheng Man,27,164,51.85,19.27795955,工程師,雪蘭莪,https://www.instagram.com/joeychocz/
// 27,朱妍彬,Ice,Chu Yen Bing,23,163,46.9,17.652151,內容創作者,吉隆坡,https://www.instagram.com/salt.ice.chu/

const PageantInfo = [
  {
    //edited
    id: 1,
    name_cn: "陳美詩",
    nickname: "Maisy",
    name_en: "Tan Mei See",
    rank: 1,
    age: 28,
    height: 164,
    weight: 50.75,
    occupation: "烘焙師",
    ig: "maisy317",
    profile_picture: NewPageant1,
    profile_picture_tiny: Pageant25Tiny,
    profile_picture_b64: Pageant25B64,
    origin: "霹雳",
    vote: 0,
  },
  {
    //edited
    id: 2,
    name_cn: "翁惠琳",
    nickname: "Lyla",
    name_en: "Ung Hei Ling",
    rank: 1,
    age: 24,
    height: 172,
    weight: 53.65,
    occupation: "美睫師",
    ig: "lyla.ung",
    profile_picture: NewPageant2,
    profile_picture_tiny: Pageant13Tiny,
    profile_picture_b64: Pageant13B64,
    origin: "砂拉越",
    vote: 0,
  },
  {
    //edited
    id: 3,
    name_cn: "陳湘維",
    nickname: "Nicole",
    name_en: "Tan Siang Wei",
    rank: 1,
    age: 22,
    height: 158,
    weight: 46.2,
    occupation: "特殊兒童治療師/學生",
    ig: "nicaboo.t",
    profile_picture: NewPageant3,
    profile_picture_tiny: Pageant20Tiny,
    profile_picture_b64: Pageant20B64,
    origin: "雪蘭莪",
    vote: 0,
  },
  {
    id: 4,
    name_cn: "雷欣蕙",
    nickname: "Luwee",
    name_en: "Luwe Xin Hui",
    rank: 1,
    age: 23,
    height: 158,
    weight: 47.9,
    occupation: "網紅",
    ig: "luweexxi",
    profile_picture: NewPageant4,
    profile_picture_tiny: Pageant5Tiny,
    profile_picture_b64: Pageant5B64,
    origin: "马六甲",
    vote: 0,
  },
  {
    //edited
    id: 5,
    name_cn: "黃欣琳",
    nickname: "Serene",
    name_en: "Ng Xin Lin",
    rank: 1,
    age: 25,
    height: 169,
    weight: 44.7,
    occupation: "秘書",
    ig: "serene_nxl",
    profile_picture: NewPageant5,
    profile_picture_tiny: Pageant12Tiny,
    profile_picture_b64: Pageant12B64,
    origin: "柔佛",
    vote: 0,
  },
  {
    //edited
    id: 6,
    name_cn: "林靖欣",
    nickname: "Ashley",
    name_en: "Ashley Lim Jinq Xin",
    rank: 1,
    age: 28,
    height: 159,
    weight: 43.5,
    occupation: "油管博主",
    ig: "ashleylimjinq",
    profile_picture: NewPageant6,
    profile_picture_tiny: Pageant2Tiny,
    profile_picture_b64: Pageant2B64,
    origin: "雪兰莪",
    vote: 0,
  },
  {
    //edited
    id: 7,
    name_cn: "朱妍彬",
    nickname: "Ice",
    name_en: "Chu Yen Bing",
    rank: 1,
    age: 23,
    height: 163,
    weight: 46.9,
    occupation: "內容創作者",
    ig: "salt.ice.chu",
    profile_picture: NewPageant7,
    profile_picture_tiny: Pageant27Tiny,
    profile_picture_b64: Pageant27B64,
    origin: "吉隆坡",
    vote: 0,
  },

  {
    //edited
    id: 8,
    name_cn: "李茹瑩",
    nickname: "Joveen",
    name_en: "Lee Joveen",
    rank: 1,
    age: 23,
    height: 165,
    weight: 53.9,
    occupation: "學生",
    ig: "__j.veen",
    profile_picture: NewPageant8,
    profile_picture_tiny: Pageant23Tiny,
    profile_picture_b64: Pageant23B64,
    origin: "檳城",
    vote: 0,
  },
  {
    //edited
    id: 9,
    name_cn: "葉惠瑩",
    nickname: "Hayley Yeap",
    name_en: "Yeap Hui Yin",
    rank: 1,
    age: 26,
    height: 168,
    weight: 48.05,
    occupation: "空姐",
    ig: "hayleyhuiyin",
    profile_picture: NewPageant9,
    profile_picture_tiny: Pageant16Tiny,
    profile_picture_b64: Pageant16B64,
    origin: "霹雳",
    vote: 0,
  },
  {
    //edited
    id: 10,
    name_cn: "李靖雯",
    nickname: "Joey",
    name_en: "Joey Lee Cheng Man",
    rank: 1,
    age: 27,
    height: 164,
    weight: 51.85,
    occupation: "工程師",
    ig: "joeychocz",
    profile_picture: NewPageant10,
    profile_picture_tiny: Pageant26Tiny,
    profile_picture_b64: Pageant26B64,
    origin: "雪蘭莪",
    vote: 0,
  },
  {
    //edited
    id: 11,
    name_cn: "池玲德",
    nickname: "Reina",
    name_en: "Rinachelle Dieh Ling Teck",
    rank: 1,
    age: 23,
    height: 163,
    weight: 47.25,
    occupation: "網紅",
    ig: "reina_nana1014",
    profile_picture: NewPageant11,

    profile_picture_tiny: Pageant3Tiny,
    profile_picture_b64: Pageant3B64,
    origin: "沙巴",
    vote: 0,
  },
  {
    //edited
    id: 12,
    name_cn: "周詩穎",
    nickname: "Seocy",
    name_en: "Chew Sei Ing",
    rank: 1,
    age: 28,
    height: 167,
    weight: 49.55,
    occupation: "商人",
    ig: "seocychew",
    profile_picture: NewPageant12,
    profile_picture_tiny: Pageant24Tiny,
    profile_picture_b64: Pageant24B64,
    origin: "柔佛",
    vote: 0,
  },
  {
    //edited
    id: 13,
    name_cn: "邱心兒",
    nickname: "",
    name_en: "Kew Xin Er",
    rank: 1,
    age: 18,
    height: 159,
    weight: 38.25,
    occupation: "學生",
    ig: "xinerr.x",
    profile_picture: NewPageant13,
    profile_picture_tiny: Pageant1Tiny,
    profile_picture_b64: Pageant1B64,
    origin: "吉隆坡",
    vote: 0,
  },
  {
    //edited
    id: 14,
    name_cn: "黃詩淋",
    nickname: "Carmen",
    name_en: "Wong Si Mun",
    rank: 1,
    age: 24,
    height: 163,
    weight: 42.8,
    occupation: "銷售主管",
    ig: "carmenwg_",
    profile_picture: NewPageant14,
    profile_picture_tiny: Pageant21Tiny,
    profile_picture_b64: Pageant21B64,
    origin: "霹雳",
    vote: 0,
  },
  {
    //edited
    id: 15,
    name_cn: "黃美琪",
    nickname: "",
    name_en: "Yoshiko Wong Mei Chi",
    rank: 1,
    age: 26,
    height: 160,
    weight: 41.05,
    occupation: "內容創作者",
    ig: "yoyostrawberrychichi",
    profile_picture: NewPageant15,
    profile_picture_tiny: Pageant14Tiny,
    profile_picture_b64: Pageant14B64,
    origin: "吉隆坡",
    vote: 0,
  },
];

export const MissPageantInfo = [
  {
    id: 1,
    name_cn: "鐘炘彤",
    nickname: "Janice",
    name_en: "Chian Yan Tong",
    rank: 1,
    age: 24,
    height: 171,
    weight: 52,
    occupation: "實習律師",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/1.1%20Janice%20%E9%92%9F%E7%82%98%E5%BD%A4.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/1.1%20Janice%20%E9%92%9F%E7%82%98%E5%BD%A4.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/1.1%20Janice%20%E9%92%9F%E7%82%98%E5%BD%A4.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "芭蕾舞，旅行",
    body: "34C-24-36",
    gd: 0,
  },
  {
    id: 2,
    name_cn: "Ranny",
    nickname: "Ranny",
    name_en: "Ngoc Thao Nguyen",
    rank: 1,
    age: 23,
    height: 168,
    weight: 50,
    occupation: "平面模特",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/2.1%20Ranny%20Vietnam%F0%9F%87%BB%F0%9F%87%B3.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/2.1%20Ranny%20Vietnam%F0%9F%87%BB%F0%9F%87%B3.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/2.1%20Ranny%20Vietnam%F0%9F%87%BB%F0%9F%87%B3.jpg`,
    origin: "越南",
    vote: 0,
    hobby: "唱歌",
    body: "32A-23-35",
    gd: 0,
  },
  {
    id: 3,
    name_cn: "Anggun",
    nickname: "Anggun",
    name_en: "Philomena Anggun",
    rank: 1,
    age: 18,
    height: 176,
    weight: 52,
    occupation: "模特",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/3.1%20Anggun%20Indonesia%F0%9F%87%AE%F0%9F%87%A9.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/3.1%20Anggun%20Indonesia%F0%9F%87%AE%F0%9F%87%A9.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/3.1%20Anggun%20Indonesia%F0%9F%87%AE%F0%9F%87%A9.jpg`,
    origin: "Indonesia",
    vote: 0,
    gd: 0,
  },
  {
    id: 4,
    name_cn: "賴淑馨",
    nickname: "Suzanne",
    name_en: "Lay Soke Xin",
    rank: 1,
    age: 24,
    height: 170,
    weight: 50,
    occupation: "數碼營銷專員",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/4.1%20Suzanne%20%E8%B5%96%E6%B7%91%E9%A6%A8.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/4.1%20Suzanne%20%E8%B5%96%E6%B7%91%E9%A6%A8.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/4.1%20Suzanne%20%E8%B5%96%E6%B7%91%E9%A6%A8.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "跳舞",
    body: "32B-24-35",
    gd: 0,
  },
  {
    id: 5,
    name_cn: "方錦瑩",
    nickname: "XiaoM",
    name_en: "Michelle Hong Chin Yin",
    rank: 1,
    age: 26,
    height: 164,
    weight: 50,
    occupation: "護膚品牌創辦人",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/5.1%20Michelle%20%E6%96%B9%E9%94%A6%E8%8E%B9.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/5.1%20Michelle%20%E6%96%B9%E9%94%A6%E8%8E%B9.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/5.1%20Michelle%20%E6%96%B9%E9%94%A6%E8%8E%B9.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "跳舞",
    body: "32C-27-33",
    gd: 0,
  },
  {
    id: 6,
    name_cn: "郭繪潁",
    nickname: "Cymbeline",
    name_en: "Koay Hui Ying",
    rank: 1,
    age: 23,
    height: 163,
    weight: 47,
    occupation: "網絡主播",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/6.1%20Cymbeline%20%E9%83%AD%E7%BB%98%E9%A2%8D.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/6.1%20Cymbeline%20%E9%83%AD%E7%BB%98%E9%A2%8D.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/6.1%20Cymbeline%20%E9%83%AD%E7%BB%98%E9%A2%8D.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "唱歌",
    body: "29A-23.5-36",
    gd: 0,
  },
  {
    id: 7,
    name_cn: "歐陽欣怡",
    nickname: "Sammy",
    name_en: "Yong Sam Yee",
    rank: 1,
    age: 25,
    height: 166,
    weight: 52,
    occupation: "數碼營銷專員",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/7.1%20Sammy%20%E6%AC%A7%E9%98%B3%E6%AC%A3%E6%80%A1.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/7.1%20Sammy%20%E6%AC%A7%E9%98%B3%E6%AC%A3%E6%80%A1.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/7.1%20Sammy%20%E6%AC%A7%E9%98%B3%E6%AC%A3%E6%80%A1.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "旅行，吃，聊天",
    body: "32A-25-35",
    gd: 0,
  },
  {
    id: 8,
    name_cn: "宋秀梅",
    nickname: "Seavmey",
    name_en: "Song Seavmey",
    rank: 1,
    age: 27,
    height: 163,
    weight: 45,
    occupation: "置業顧問",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/8.1%20Seavmey%20Cambodia%F0%9F%87%B0%F0%9F%87%AD.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/8.1%20Seavmey%20Cambodia%F0%9F%87%B0%F0%9F%87%AD.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/8.1%20Seavmey%20Cambodia%F0%9F%87%B0%F0%9F%87%AD.jpg`,
    origin: "柬埔寨",
    vote: 0,
    hobby: "唱歌",
    body: "32A-23-35",
    gd: 0,
  },
  {
    id: 9,
    name_cn: "柯紫恬",
    nickname: "",
    name_en: "Koh Zi Tian",
    rank: 1,
    age: 21,
    height: 167,
    weight: 46,
    occupation: "法律系學生",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/9.1%20%E6%9F%AF%E7%B4%AB%E6%81%AC%20.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/9.1%20%E6%9F%AF%E7%B4%AB%E6%81%AC%20.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/9.1%20%E6%9F%AF%E7%B4%AB%E6%81%AC%20.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "跳舞",
    body: "28B-25.5-33.5",
    gd: 0,
  },
  {
    id: 10,
    name_cn: "文希婷",
    nickname: "Sherlyn",
    name_en: "Heon Hi Teng",
    rank: 1,
    age: 23,
    height: 160,
    weight: 42,
    occupation: "學生",
    ig: "",
    ure: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/10.1%20Sherlyn%20%E6%96%87%E5%B8%8C%E5%A9%B7.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/10.1%20Sherlyn%20%E6%96%87%E5%B8%8C%E5%A9%B7.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/10.1%20Sherlyn%20%E6%96%87%E5%B8%8C%E5%A9%B7.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "跳舞",
    body: "31A-24.5-33",
    gd: 0,
  },
  {
    id: 11,
    name_cn: "張贏方",
    nickname: "Candy",
    name_en: "Teoh Ying Fang",
    rank: 1,
    age: 25,
    height: 163,
    weight: 46,
    occupation: "時尚品牌主理人",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/11.1%20Candy%20%E5%BC%A0%E8%B5%A2%E6%96%B9.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/11.1%20Candy%20%E5%BC%A0%E8%B5%A2%E6%96%B9.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/11.1%20Candy%20%E5%BC%A0%E8%B5%A2%E6%96%B9.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "跳舞",
    body: "30.5B-23.5-33.5",
  },
  {
    id: 12,
    name_cn: "Sheila",
    nickname: "Sheila",
    name_en: "Sheila Maey",
    rank: 1,
    age: 24,
    height: 165,
    weight: 56,
    occupation: "自由工作者",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/12.1%20Sheila%20Philippines🇵🇭.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/12.1%20Sheila%20Philippines🇵🇭.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/12.1%20Sheila%20Philippines🇵🇭.jpg`,
    origin: "菲律賓",
    vote: 0,
    hobby: "文化舞蹈",
    body: "34C-26-40",
    gd: 0,
  },
  {
    id: 13,
    name_cn: "Minnie",
    nickname: "Minnie",
    name_en: "Siang Wong",
    rank: 1,
    age: 27,
    height: 175,
    weight: 51,
    occupation: "模特",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/13.1%20Minnie%20Thailand%F0%9F%87%B9%F0%9F%87%AD.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/13.1%20Minnie%20Thailand%F0%9F%87%B9%F0%9F%87%AD.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/13.1%20Minnie%20Thailand%F0%9F%87%B9%F0%9F%87%AD.jpg`,
    origin: "泰國",
    vote: 0,
    hobby: "唱歌，跳舞",
    body: "31B-24-36",
    gd: 0,
  },
  {
    id: 14,
    name_cn: "廖秀月",
    nickname: "Roxanne",
    name_en: "Liew Siew Yuet",
    rank: 1,
    age: 23,
    height: 170,
    weight: 56,
    occupation: "模特",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/14.1%20Rozanne%20%E5%BB%96%E7%A7%80%E6%9C%88.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/14.1%20Rozanne%20%E5%BB%96%E7%A7%80%E6%9C%88.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/14.1%20Rozanne%20%E5%BB%96%E7%A7%80%E6%9C%88.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "空舞，普拉提",
    body: "31B-26-37",
    gd: 0,
  },
  {
    id: 15,
    name_cn: "Gugu",
    nickname: "Gugu",
    name_en: "Haymar Aye Thiengi",
    rank: 1,
    age: 18,
    height: 170,
    weight: 49,
    occupation: "平面模特",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/15.1%20Gugu%20Myanmar%F0%9F%87%B2%F0%9F%87%B2.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/15.1%20Gugu%20Myanmar%F0%9F%87%B2%F0%9F%87%B2.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/15.1%20Gugu%20Myanmar%F0%9F%87%B2%F0%9F%87%B2.jpg`,
    origin: "緬甸",
    vote: 0,
    hobby: "傳統舞蹈",
    body: "33B-24-35",
    gd: 0,
  },
  {
    id: 16,
    name_cn: "丁詩莉",
    nickname: "Ashley",
    name_en: "Ashley Ting Tze Li",
    rank: 1,
    age: 20,
    height: 162,
    weight: 45,
    occupation: "學生",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/16.1%20Ashley%20%E4%B8%81%E8%AF%97%E8%8E%89.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/16.1%20Ashley%20%E4%B8%81%E8%AF%97%E8%8E%89.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/16.1%20Ashley%20%E4%B8%81%E8%AF%97%E8%8E%89.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "跳舞，拍照",
    body: "32B-23-34",
    gd: 0,
  },
  {
    id: 17,
    name_cn: "叶翠儿",
    nickname: "CL",
    name_en: "Yap Chooi Lie",
    rank: 2,
    age: 27,
    height: 170,
    weight: 53,
    occupation: "自雇人士",
    ig: "-",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/17.1%20CL%20叶翠儿.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/17.1%20CL%20叶翠儿.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/missasia%20jpg/17.1%20CL%20叶翠儿.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "-",
    body: "33C-26-35",
    gd: 0,
  },
];

export const MrPageantInfo = [
  {
    id: 1,
    name_cn: "黃昱錡",
    nickname: "Ricky",
    name_en: "Wong Ricky",
    rank: 1,
    age: 24,
    height: 189,
    weight: 82,
    occupation: "珠寶設計師",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/1.1%20%E9%BB%84%E6%98%B1%E9%8C%A1%20Ricky.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/1.1%20%E9%BB%84%E6%98%B1%E9%8C%A1%20Ricky.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/1.1%20%E9%BB%84%E6%98%B1%E9%8C%A1%20Ricky.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "Beatbox，運動，時尚",
    body: "",
    gd: 1,
  },
  {
    id: 2,
    name_cn: "王幃綘",
    nickname: "Henry",
    name_en: "Wong Yao Hong",
    rank: 1,
    age: 22,
    height: 179,
    weight: 62,
    occupation: "影片剪輯師",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/2.1%20%E7%8E%8B%E5%B9%83%E7%B6%98%20Henry.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/2.1%20%E7%8E%8B%E5%B9%83%E7%B6%98%20Henry.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/2.1%20%E7%8E%8B%E5%B9%83%E7%B6%98%20Henry.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "電動，動漫",
    body: "",
    gd: 1,
  },
  {
    id: 3,
    name_cn: "李浩軒",
    nickname: "Shaun",
    name_en: "Lee How Xuan",
    rank: 1,
    age: 20,
    height: 179.5,
    weight: 77,
    occupation: "學生",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/3.1%20%E6%9D%8E%E6%B5%A9%E8%BD%A9%20Shaun.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/3.1%20%E6%9D%8E%E6%B5%A9%E8%BD%A9%20Shaun.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/3.1%20%E6%9D%8E%E6%B5%A9%E8%BD%A9%20Shaun.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "健身，耍帥，唱歌，Rap",
    body: "",
    gd: 1,
  },
  {
    id: 4,
    name_cn: "張霆傑",
    nickname: "Vincent Chong",
    name_en: "Chong Teng Kit",
    rank: 1,
    age: 22,
    height: 178,
    weight: 68,
    occupation: "手錶批發商行銷總監",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/4.1%20%E5%BC%A0%E9%9C%86%E6%9D%B0%20Vincent%20Chong.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/4.1%20%E5%BC%A0%E9%9C%86%E6%9D%B0%20Vincent%20Chong.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/4.1%20%E5%BC%A0%E9%9C%86%E6%9D%B0%20Vincent%20Chong.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "運動，拍攝，模特",
    body: "",
    gd: 1,
  },
  {
    id: 5,
    name_cn: "蔡其紋",
    nickname: "Kelvin",
    name_en: "Chai Kai Wen",
    rank: 1,
    age: 22,
    height: 170,
    weight: 62,
    occupation: "大四牙科生",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/5.1%20%E8%94%A1%E5%85%B6%E7%BA%B9%20Kelvin.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/5.1%20%E8%94%A1%E5%85%B6%E7%BA%B9%20Kelvin.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/5.1%20%E8%94%A1%E5%85%B6%E7%BA%B9%20Kelvin.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "健身，讀書",
    body: "",
    gd: 1,
  },
  {
    id: 6,
    name_cn: "何浩誠",
    nickname: "Darren",
    name_en: "Ho Hau Chern",
    rank: 1,
    age: 18,
    height: 178,
    weight: 61,
    occupation: "學生",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/6.1%20%E4%BD%95%E6%B5%A9%E8%AF%9A%20Darren.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/6.1%20%E4%BD%95%E6%B5%A9%E8%AF%9A%20Darren.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/6.1%20%E4%BD%95%E6%B5%A9%E8%AF%9A%20Darren.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "運動，看戲",
    body: "",
    gd: 1,
  },
  {
    id: 7,
    name_cn: "謝佳運",
    nickname: "Vincent Chye",
    name_en: "Vincent Chye Chia Yun",
    rank: 1,
    age: 24,
    height: 170,
    weight: 68,
    occupation: "脊椎神經醫師",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/7.1%20%E8%B0%A2%E4%BD%B3%E8%BF%90%20Vincent%20Chye.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/7.1%20%E8%B0%A2%E4%BD%B3%E8%BF%90%20Vincent%20Chye.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/7.1%20%E8%B0%A2%E4%BD%B3%E8%BF%90%20Vincent%20Chye.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "健身",
    body: "",
    gd: 1,
  },
  {
    id: 8,
    name_cn: "龍仕凱",
    nickname: "Sky",
    name_en: "Loong Sze Kai",
    rank: 1,
    age: 29,
    height: 184,
    weight: 85,
    occupation: "健身教練",
    ig: "",
    profile_picture: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/8.1%20%E9%BE%99%E4%BB%95%E5%87%AF%20Sky.jpg`,
    profile_picture_tiny: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/8.1%20%E9%BE%99%E4%BB%95%E5%87%AF%20Sky.jpg`,
    profile_picture_b64: `https://livevote.oss-ap-southeast-3.aliyuncs.com/mrasia%20jpg/8.1%20%E9%BE%99%E4%BB%95%E5%87%AF%20Sky.jpg`,
    origin: "馬來西亞",
    vote: 0,
    hobby: "健身",
    body: "",
    gd: 1,
  },
];

export default PageantInfo;
