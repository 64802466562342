import React, { Component, Suspense } from "react";
import "./App.css";
// import packageJson from "../package.json";
import { DialogPack } from "./configs/DialogConstants";
import DialogBox from "../src/components/DialogBox";
import { EventBus } from "./utilities/EventBus";
import { HashLoader } from "react-spinners";
import { Snackbar } from "@mui/material";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoadingPage from "./pages/LoadingPage";
import packageJson from "../package.json";
import OneSignal from "react-onesignal";
import LiveVote from "./pages/LiveVote";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      loadingTitle: "载入中",
      loadingMessage: [],
      dialogPack: DialogPack,
      loading: true,
      url: "",
      initalizedOneSignal: false,
    };
    this.scrollbarRef = React.createRef();

    this.initLoading = this.initLoading.bind(this);
    this.initDialog = this.initDialog.bind(this);

    this.currentDialogPriority = 0;
    this.dialogPacks = [];

    this.handleShowLoadingMessageCallback =
      this.handleShowLoadingMessageCallback.bind(this);
    this.handleHideLoadingMessageCallback =
      this.handleHideLoadingMessageCallback.bind(this);
    this.handleUpdateLoadingMessageCallbackl =
      this.handleUpdateLoadingMessageCallback.bind(this);
    this.getProperLoadingMessages = this.getProperLoadingMessages.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.editStoreItemDialog = React.createRef();
  }

  componentDidMount() {
    console.log(`Version : v${packageJson.version}`);
    console.log(
      "%cDesigned and developed by",
      "color: #0F8BD1; font-size: 12px"
    );
    console.log(
      "%c⏻aks %cSolution and Studio ",
      "color: #42E501; font-size: 15px; font-weight: 900",
      "color: #0F8BD1; font-size: 15px; font-weight: 900"
    );
    console.log(
      "%cContact us via: 📬 %cinquiry@the-oaks.my",
      "color: #04CFFF; font-size: 12px",
      "color: #0F8BD1; font-size: 12px; font-weight: 700"
    );
    console.log("------------------");
    console.log(
      "%cCredit:",
      "color: #42E501; font-size: 12px; font-weight: 700"
    );
    console.log(
      "%cLye Seng Wai %clye@the-oaks.my",
      "color: #04CFFF; font-size: 12px;",
      "color: #FF8BD1; font-size: 12px; font-weight: 700"
    );
    console.log(
      "%cChan Kar Hoong %ckarhoong@the-oaks.my",
      "color: #04CFFF; font-size: 12px;",
      "color: #FF8BD1; font-size: 12px; font-weight: 700"
    );
    console.log(
      "%cChen Yi Chun %cyi@the-oaks.my",
      "color: #04CFFF; font-size: 12px;",
      "color: #FF8BD1; font-size: 12px; font-weight: 700"
    );

    this.initiateEventBus();
    this.handleResize();
    this.initOneSignal();
    window.addEventListener("resize", this.handleResize);
  }

  initOneSignal() {
    OneSignal.init({
      appId: "5e83d2ae-9ae8-40a8-9d0a-94fddffbf40d",
    }).then(() => {
      this.setState({ initalizedOneSignal: true });
      OneSignal.Slidedown.promptPush();
      // OneSignal.push(["promptForPushNotifications"]);
    });
  }

  handleResize() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    EventBus.remove("loading", this.initLoading);
    EventBus.remove("dialog", this.initDialog);
  }

  initiateEventBus() {
    EventBus.on("loading", this.initLoading);
    EventBus.on("dialog", this.initDialog);
    EventBus.on("changeURL", (url) => {
      this.setState({ url: url });
    });
  }

  initLoading(data) {
    if (data.loadingMessage && !Array.isArray(data.loadingMessage)) {
      data.loadingMessage = [data.loadingMessage];
    }
    this.setState({
      showLoading: data.showLoading,
      loadingTitle: data.loadingTitle,
      loadingMessage: data.loadingMessage ?? [],
    });
  }

  initDialog(data) {
    this.setState({
      dialogPack: data.dialogPack,
    });
  }

  handleShowLoadingMessageCallback(loadingTitle, loadingMessage = []) {
    this.setState({
      loading: true,
      loadingTitle: loadingTitle,
      loadingMessage: loadingMessage,
    });
  }

  handleHideLoadingMessageCallback() {
    this.setState({
      loading: false,
      loadingTitle: "",
      loadingMessage: [],
    });
  }

  handleUpdateLoadingMessageCallback(message) {
    var currentLoadingMessage = this.state.loadingMessage;
    currentLoadingMessage.push(message);
    currentLoadingMessage = currentLoadingMessage.slice(
      Math.max(currentLoadingMessage.length - 5, 0)
    );

    this.setState({ loadingMessage: currentLoadingMessage });
  }

  getProperLoadingMessages() {
    if (
      this.state.loadingMessage &&
      !Array.isArray(this.state.loadingMessage)
    ) {
      return ["Loading"];
    }
    return this.state.loadingMessage ?? [];
  }

  closeSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ showSnackbar: false });
  };

  render() {
    return (
      <div
        ref={this.scrollbarRef}
        id="main-scroller"
        className="App"
        style={{ height: this.state.height }}
      >
        <BrowserRouter basename="/">
          <Routes>
            <Route
              index
              path="/"
              element={
                <Suspense fallback={<LoadingPage />}>
                  <LiveVote scrollbarRef={this.scrollbarRef} />
                </Suspense>
              }
            />
          </Routes>
        </BrowserRouter>
        {/* <div className="version">v{packageJson.version} 内部测试版</div> */}
        {this.state.dialogPack.showDialog && (
          <DialogBox dialogPack={this.state.dialogPack} />
        )}
        {this.state.showLoading ? (
          <div
            style={{
              height: "100vh",
              width: "100%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0,0,0,0.75)",
              flexDirection: "column",
              top: 0,
              zIndex: 9999,
            }}
          >
            <div>
              <HashLoader
                size={24}
                color="white"
                speedMultiplier={0.5}
                margin={10}
              />
            </div>

            <div>
              <p className="white-title">{this.state.loadingTitle}</p>
            </div>
            <div className="bottom-right-info">
              {/* {this.getProperLoadingMessages().map((eachMessage, i) => (
                <p
                  key={`message-${i}`}
                  className="white-title small"
                  style={{
                    opacity: 1 / (this.state.loadingMessage.length - i),
                  }}>
                  {eachMessage}
                </p>
              ))} */}
            </div>
          </div>
        ) : null}
        <Snackbar
          open={this.state.showSnackbar}
          autoHideDuration={6000}
          onClose={this.closeSnackbar}
          message={this.state.snackbarMessage}
          // action={action}
        />
      </div>
    );
  }
}

export default App;
