import React, { Component } from "react";
import "./BasicDialog.scss";
import PropTypes from "prop-types";
import { getProperLanguage } from "../utilities/Utilities";

export default class BasicDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.translate = this.translate.bind(this);
  }

  componentDidMount() {}

  translate(text) {
    if (this.props.language === "zh") {
      return text;
    } else {
      return getProperLanguage(text, true);
    }
  }

  render() {
    // const language = this.props.language ?? "zh";
    const { show, enText, zhText, zhTitle } = this.props;
    // const pageant = this.props.pageant ?? DefaultPageant;
    return (
      <div className={`dialog-wrapper ${show && "enable"}`}>
        <div className="dialog-background">
          <div className="dialog-main-container">
            <div className="top-bar">
              <div
                className="close-btn"
                onClick={() => {
                  this.props.onClose();
                }}
              >
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="30" cy="30" r="30" fill="#F8F6F4" />
                  <path
                    d="M40.21 20.0629L19.9412 40.3316"
                    stroke="#dcac60"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.9385 20.0612L40.2072 40.3299"
                    stroke="#dcac60"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className="dialog-title">
              <div className="zh">
                <p>{zhTitle} </p>
              </div>
              {/* <div className="en">
                <p>{enTitle}</p>
              </div> */}
            </div>

            <div className="dialog-body">
              <div className="zh">
                <p>{zhText}</p>
              </div>
              <div className="en">
                <p>{enText}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BasicDialog.propTypes = {
  pageant: PropTypes.object,
  onClose: PropTypes.func,
  voteCount: PropTypes.number,
};

BasicDialog.defaultProps = {
  pageant: null,
  onClose: () => {},
  voteCount: 0,
};
