import React, { Component, Suspense } from "react";
import "./PageantSection.scss";
import Contestant from "./Contestant";

export default class PageantSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { pageantInfo, showPageantCallback, pageantNaming,  } = this.props;
    return (
      <div className="live-vote-bottom-section">
        <section className="home-page-contestants" id="pageant-list">
          <div
            className="home-page-contestant-list"
            style={{ maxWidth: "100%" }}
            id="home-page-contestant-list-group"
          >
            {pageantInfo.map((eachPageant, index) => {
              return (
                <Suspense
                  key={`contestant-${index}`}
                  //   fallback={this.contestantFallback()}
                >
                  <Contestant
                    liveVoteMode={true}
                    language={this.state.language}
                    pageant={eachPageant}
                    showPageantCallback={() => showPageantCallback(eachPageant)}
                    pageantNaming={pageantNaming}
                  />
                </Suspense>
              );
            })}
          </div>
        </section>
      </div>
    );
  }
}
