import "./Crown.scss";

export default function Crown(props) {
  const pageant = props.pageant || {};

  const getRankingText = () => {
    pageant.rank = pageant.rank || 0;
    if (pageant.vote <= 0) return "other";
    if (pageant.rank === 1) {
      return "first";
    }
    if (pageant.rank === 2) {
      return "second";
    }
    if (pageant.rank === 3) {
      return "third";
    }
    if (pageant.rank > 7) {
      return "out-of-range";
    }
    return "other";
  };
  return (
    <div
      className={`contestant-crown ${getRankingText()} ${
        props.plain ? "plain" : ""
      }`}>
      <svg
        width="45"
        height="30"
        viewBox="0 0 45 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="_&#229;&#156;&#150;&#229;&#177;&#164;_1">
          <path
            id="Vector"
            d="M3.88579 2.89241L12.7865 10.997L20.7167 0.89313C21.5322 -0.143911 23.0052 -0.300775 24.0073 0.543092C24.1449 0.658561 24.2691 0.790007 24.3772 0.934524L31.9445 10.9948L41.133 2.77404C42.1113 1.9004 43.5885 2.01296 44.4321 3.02531C44.8742 3.55618 45.0728 4.25916 44.976 4.95269L41.467 30H3.58894L0.0259824 5.06671C-0.164903 3.74427 0.716538 2.5126 1.99449 2.31507C2.67662 2.20977 3.36858 2.4211 3.88579 2.89241Z"
          />
        </g>
      </svg>
      <span>{pageant.rank}</span>
    </div>
  );
}
