import React, { Component } from "react";
import "./SuccessDialog.scss";
import PropTypes from "prop-types";
import { base64ToImage, getProperLanguage } from "../utilities/Utilities";
import { DefaultPageant } from "../configs/Default";
import Fireworks from "../assets/images/full/fireworks.svg";
import FadeLoadImage from "../components/FadeLoadImage";
import OSASLogo from "../assets/images/full/osas-logo.png";

class SuccessDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmationMode: false,
      inputVote: 20,
      swipeMode: "",
      time: 0,
    };

    this.translate = this.translate.bind(this);
  }

  componentDidMount() {}

  translate(text) {
    if (this.props.language === "zh") {
      return text;
    } else {
      return getProperLanguage(text, true);
    }
  }

  render() {
    const language = this.props.language ?? "zh";
    const pageant = this.props.pageant ?? DefaultPageant;
    return (
      <div className={`success-dialog-wrapper ${pageant.id !== 0 && "enable"}`}>
        <div className="success-dialog-container">
          <div className="main">
            <div className="backdrop-edge">
              <div className="backdrop">
                <div className="top-bar">
                  <div className="close-btn"></div>
                  <div className="success-dialog-title-wrapper">
                    <div
                      className={`success-dialog-title ${
                        language === "zh" ? "" : "sc"
                      }`}
                    >
                      <p>{this.translate("投票專區")}</p>
                    </div>
                  </div>
                  <div
                    className="close-btn"
                    onClick={() => {
                      this.props.onClose();
                      setTimeout(() => {
                        this.setState({
                          confirmationMode: false,
                        });
                      });
                    }}
                  >
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="30" cy="30" r="30" fill="#F8F6F4" />
                      <path
                        d="M40.21 20.0629L19.9412 40.3316"
                        stroke="#dcac60"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.9385 20.0612L40.2072 40.3299"
                        stroke="#dcac60"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div id="title">
                  <img src={Fireworks} alt="" />
                  <div id="label">恭喜！投票成功</div>
                </div>
                <div id="content">
                  <FadeLoadImage
                    preloadThumb={base64ToImage(pageant.profile_picture_b64)}
                    fullSize={pageant.profile_picture}
                    style={{
                      objectFit: "contain",
                      borderRadius: 20,
                      aspectRatio: 1,
                      height: "100%",
                      objectPosition: "top",
                    }}
                    wrapperStyle={{
                      justifyContent: "flex-start",
                      borderRadius: 20,
                      maxHeight: 320,
                      aspectRatio: 1,
                      width: "fit-content",
                      background:
                        "linear-gradient(156deg, #D777B580 0%, #C883DB80 49.36%, #41B0D880 100%), #EFEFEF",
                    }}
                  />
                  <div className="success-dialog-thanks-wrapper">
                    <div className="success-dialog-thanks">
                      <span className="success-dialog-name-cn">
                        {pageant.name_cn}
                      </span>
                      <span className="success-dialog-name-en">
                        {pageant.name_en}
                      </span>
                    </div>
                    <div
                      style={{
                        fontSize: 12,
                        marginBottom: 16,
                        color: "#00000080",
                        width: "100%",
                      }}
                    >
                      <p style={{ margin: "unset" }}>Developed by</p>
                      <p style={{ margin: "unset" }}>
                        Oaks Solution and Studio Sdn Bhd
                      </p>
                      <img src={OSASLogo} style={{ width: 32 }} alt="" />
                      <p style={{ margin: "unset" }}>© 亞視新聞東南亞</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SuccessDialog.propTypes = {
  pageant: PropTypes.object,
  onClose: PropTypes.func,
  voteCount: PropTypes.number,
};

SuccessDialog.defaultProps = {
  pageant: null,
  onClose: () => {},
  voteCount: 0,
};

export default SuccessDialog;
