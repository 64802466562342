//create a class bases component with proper imports

import React, { Component } from "react";

import "./LiveVote.scss";
import "./HomePage.scss";
import withRouter from "../utilities/withRouter";
import { getVotesRanking, votePageantPrivate } from "../utilities/Requests";
import { browserKeyGenerator, getProperLanguage } from "../utilities/Utilities";
import AtvLogoLiveVote from "../assets/images/small/atv-logo-live-vote.png";
import BannerDesktop from "../assets/images/large/atv-35-banner.png";
import BannerTitleDesktop from "../assets/images/large/atv-35-banner-title.png";
import BannerLaptop from "../assets/images/small/atv-35-banner.png";
import BannerTitlelaptop from "../assets/images/large/atv-35-banner-title.png";

// import VideoCover from "../assets/images/full/video-cover.png";
import { MissPageantInfo, MrPageantInfo } from "../configs/PageantInfo";
import VoteContestantDialog from "../dialogs/VoteContestantDialog";
import SuccessDialog from "../dialogs/SuccessDialog";
import BasicDialog from "../dialogs/BasicDialog";
import APIResult from "../configs/APIResult";
import { HideLoading, ShowLoading } from "../utilities/EventBus";
import OSASLogo from "../assets/images/full/osas-logo.png";
import BarChart from "../components/BarChart";
import PageantSection from "../components/PageantSection";

const autoScrollerBaseMiss = {
  1: 0,
  3: 0,
  6: 0,
  9: 0,
  12: 0,
  15: 0,
};

const autoScrollerBaseMr = {
  1: 0,
  3: 0,
  5: 0,
  7: 0,
};
class LiveVote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      width: 0,
      height: 0,
      language: "zh",
      eventVideoPlaying: false,
      //   pointHistory: DefaultPointHistory,
      //   voteInfo: DefaultVoteInfo,
      showMenu: false,
      showFloatTopBar: false,
      isVoting: false,
      showMessageDialog: false,
      message: "網路不穩，請稍後再試",
      // MissPageantInfo: MissPageantInfo,
      // MrPageantInfo: MrPageantInfo,
      showPageant: null,
      resultPageant: null,
      resultVoteCount: 0,
      resultVoterInfo: null,
      pageantMissAutoScroller: { ...autoScrollerBaseMiss },
      pageantMrAutoScroller: { ...autoScrollerBaseMr },
      showPageantAutoScroller: false,
      showLeaderboard: false,
      browserKey: "",
      votesRanking: [],
      windowWidth: 0,
      isMrAutoScrollerVisible: false,
      isScrollRegistered: false,
      tab: 0,
      votingTimeout: false,
    };
    this.performVote = this.performVote.bind(this);
    this.mrSectionRef = React.createRef();
    this.observer = null;
  }

  getPageantNamingBasedOnGender = () => {
    console.log(
      this.state.isMrAutoScrollerVisible ? "#pageant-mr" : "#pageant-miss",
      " MEOW"
    );
    return this.state.isMrAutoScrollerVisible ? "#pageant-mr" : "#pageant-miss";
  };

  getAutoScrollerBasedOnGender = () => {
    return this.state.isMrAutoScrollerVisible
      ? autoScrollerBaseMr
      : autoScrollerBaseMiss;
  };

  translate(text) {
    if (this.state.language === "zh") {
      return text;
    } else {
      return getProperLanguage(text, true);
    }
  }

  nextPrevPageant(direction) {
    // -1 is prev, 1 is next
    let currentId = this.state.showPageant.id;
    let nextId = currentId + direction;
    if (nextId > MissPageantInfo.length) {
      nextId = 1;
    } else if (nextId < 1) {
      nextId = MissPageantInfo.length;
    }
    // find the pageant from MissPageantInfo
    let nextPageant = MissPageantInfo.find((eachPageant) => {
      return eachPageant.id === nextId;
    });
    this.setState({
      showPageant: nextPageant,
    });
  }

  async performVote(phoneNumber, pageantID, tableNo) {
    let browserKey = localStorage.getItem("browser-key");
    if (!localStorage.getItem("browser-key")) {
      browserKey = browserKeyGenerator();
      localStorage.setItem("browser-key", browserKey);
    }

    // let newPhoneNumber = "";
    // for (let i = 0; i < phoneNumber.length; i++) {
    //   if (phoneNumber[i] === "0") {
    //     newPhoneNumber = phoneNumber.substring(i);
    //     break;
    //   }
    // }
    // phoneNumber = newPhoneNumber;
    // remove spaces and special characters from phone number
    phoneNumber = phoneNumber.replace(/[^0-9]/g, "");

    ShowLoading(true);
    let currentPageant = { ...this.state.showPageant };
    this.setState({
      isVoting: true,
      showPageant: null,
    });

    let result = await votePageantPrivate(
      phoneNumber,
      pageantID,
      tableNo,
      browserKey,
      window.wordpressUser.id
    );
    HideLoading(true);
    if (result.status === APIResult.SUCCESS) {
      this.setState({
        resultPageant: currentPageant,
        resultVoteCount: 0,
        resultVoterInfo: {
          timestamp: result.timestamp,
          referenceID: result.reference_id,
        },
      });
    } else if (result.status === -201) {
      this.setState({
        message: "很抱歉，鑒於每人只有一張票，佳麗於此向您道謝。",
        resultError: result.status_message,
        showMessageDialog: true,
      });
    } else if (result.status === -202) {
      this.setState({
        message: "很抱歉，此表已超出可用投票數。",
        resultError: result.status_message,
        showMessageDialog: true,
      });
    } else if (result.status === -203) {
      this.setState({
        message: "很抱歉，現場投票已結束，感謝您的參與。",
        resultError: result.status_message,
        showMessageDialog: true,
      });
    } else if (result.status === -204) {
      this.setState({
        message: "很抱歉，此電話號碼已被使用。",
        resultError: result.status_message,
        showMessageDialog: true,
      });
    } else if (result.status === -205) {
      this.setState({
        message: "很抱歉，請再次確認桌號。",
        resultError: result.status_message,
        showMessageDialog: true,
      });
    } else {
      this.setState({
        message: "網路不穩，請稍後再試。",
        resultError: result.status_message,
        showMessageDialog: true,
      });
    }
  }

  async votesRanking() {
    let result = await getVotesRanking();
    if (result.status === APIResult.SUCCESS) {
      this.setState(
        {
          votesRanking: result,
        },
        () => {
          // console.log(this.state.votesRanking);
        }
      );
    }
  }

  checkWindowWidth = () => {
    const width = window.innerWidth;
    this.setState({ windowWidth: width }, () => {
      // console.log(width);
    });
  };

  componentDidMount() {
    // this.trackScroll();
    this.votesRanking();
    this.checkWindowWidth();
    window.addEventListener("resize", this.checkWindowWidth);
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        if (
          document.querySelector(this.getAttribute("href")) ||
          document.getElementById(this.getAttribute("href"))
        ) {
          let item =
            document.querySelector(this.getAttribute("href")) ??
            document.getElementById(this.getAttribute("href"));
          document.getElementById("main-scroller").scrollTo({
            top: item.offsetTop - 128,
            left: 0,
            behavior: "smooth",
          });
          // (
          //   document.querySelector(this.getAttribute("href")) ??
          //     document.getElementById(this.getAttribute("href"))
          // )
          // .scrollIntoView({
          //   behavior: "smooth",
          // });
          // scrollIntoView - 128px smoothly
        } else {
        }
      });
    });

    const searchParams = new URLSearchParams(window.location.search);
    const pageantID = searchParams.get("pageant_id");
    const refreshSpeed = searchParams.get("refresh_speed") ?? 300000;
    setInterval(() => {}, Math.max(30000, Number(refreshSpeed)));
    if (pageantID) {
      // show vote dialog
      let pageant = MissPageantInfo.find((eachPageant) => {
        return eachPageant.id === Number(pageantID);
      });
      if (pageant) {
        this.setState({
          showPageant: pageant,
        });
      }
    }

    setInterval(() => {
      this.votesRanking();
    },10000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkWindowWidth);
  }

  trackScroll() {
    // tracking scroll of App
    let mainScroller = document.getElementById("main-scroller");
    if (mainScroller) {
      mainScroller.addEventListener("scroll", () => {
        let showFloatTopBar = false;
        if (mainScroller.scrollTop > 256) {
          showFloatTopBar = true;
        }
        if (showFloatTopBar !== this.state.showFloatTopBar) {
          this.setState({
            showFloatTopBar: showFloatTopBar,
          });
        }

        // check position of #pageant-1, #pageant-5, #pageant-10, #pageant-15, #pageant-20, #pageant-24
        let pageantListHeight =
          this.state.tab === 0
            ? { ...autoScrollerBaseMiss }
            : { ...autoScrollerBaseMr };

        let allPageantPosition = {};
        const pageantGender =
          this.state.tab === 0 ? "#pageant-miss" : "#pageant-mr";
        const currentPageantInfo =
          this.state.tab === 0 ? MissPageantInfo : MrPageantInfo;
        for (
          let eachPageant = 0;
          eachPageant < currentPageantInfo;
          eachPageant++
        ) {
          let pageantElement = document.getElementById(
            `${pageantGender}-${eachPageant}`
          );
          if (pageantElement) {
            let rect = pageantElement.getBoundingClientRect();
            allPageantPosition[eachPageant] = rect.top;
          }
        }

        Object.keys(pageantListHeight).forEach((eachPageant) => {
          let pageantElement = document.getElementById(
            `${pageantGender}-${eachPageant}`
          );
          if (pageantElement) {
            let rect = pageantElement.getBoundingClientRect();
            pageantListHeight[eachPageant] = rect.top;
          }
        });

        // find the closest pageant
        let closestPageant = 1;
        Object.keys(pageantListHeight).forEach((eachPageant) => {
          if (
            Math.abs(pageantListHeight[eachPageant]) <
            Math.abs(pageantListHeight[closestPageant])
          ) {
            closestPageant = eachPageant;
          }
        });

        let currentPageantAutoScroller =
          this.state.tab === 0
            ? { ...autoScrollerBaseMiss }
            : { ...autoScrollerBaseMr };

        // set the closest pageant to 1
        currentPageantAutoScroller[closestPageant] = 1;
        // check if pageantListHeight is different from pageantListHeight
        if (
          JSON.stringify(
            this.state.tab === 0
              ? this.state.pageantMissAutoScroller
              : this.state.pageantMrAutoScroller
          ) !== JSON.stringify(currentPageantAutoScroller)
        ) {
          this.state.tab === 0
            ? this.setState({
                pageantMissAutoScroller: currentPageantAutoScroller,
              })
            : this.setState({
                pageantMrAutoScroller: currentPageantAutoScroller,
              });
        }

        // check if pageant is in view, by checking first pageant and last pageant
        let showPageantAutoScroller = false;
        let firstPageant = document.getElementById(`${pageantGender}-1`);
        let lastPageant = document.getElementById(
          `${pageantGender}-` + currentPageantInfo.toString()
        );
        if (firstPageant && lastPageant) {
          let firstPageantRect = firstPageant.getBoundingClientRect();
          let lastPageantRect = lastPageant.getBoundingClientRect();
          if (
            firstPageantRect.top < window.innerHeight &&
            lastPageantRect.top > lastPageantRect.height * -0.25
          ) {
            showPageantAutoScroller = true;
            if (
              lastPageantRect.top + lastPageantRect.height <
              window.innerHeight * 0.75
            ) {
              showPageantAutoScroller = false;
            }
          }
        }
        if (showPageantAutoScroller !== this.state.showPageantAutoScroller) {
          this.setState({
            showPageantAutoScroller: showPageantAutoScroller,
          });
        }
      });
    }
  }

  changeTab = (tab) => {
    if (tab === this.state.tab) {
    }
    this.setState({ tab: tab });
  };

  setVotingTimeout = (value) => {
    this.setState({ votingTimeout: value }, () => {
      console.log(this.state.votingTimeout);
    });
  };
  render() {
    const { language } = this.state;
    return (
      <div className={`live-vote-parent ${language === "zh" ? "" : "sc"}`}>
        <div style={{ padding: "0.4em" }}>
          <img
            className="live-vote-logo"
            src={AtvLogoLiveVote}
            alt="AtvLogoLiveVote"
          />
        </div>
        <div className="banner-wrapper">
          <img
            className="about-event"
            src={this.state.windowWidth < 1024 ? "https://livevote.oss-ap-southeast-3.aliyuncs.com/bgbg.png" : "https://livevote.oss-ap-southeast-3.aliyuncs.com/bgbg.png"}
            alt="banner"
          />
          <div className="banner-title-wrapper">
            <img
              className="banner-title"
              src={
                this.state.windowWidth < 1024
                  ? BannerTitlelaptop
                  : BannerTitleDesktop
              }
              alt="banner-title"
            />
          </div>
        </div>
        <section className="live-vote-upper-section">
          {this.state.windowWidth < 430 ? (
            <div className="live-vote-header-message">
              <p>
                第<span>35</span>屆亞洲小姐/先生競選
              </p>
              <p>（東南亞賽區）</p>
              <p>現場人氣獎</p>
            </div>
          ) : (
            <div className="live-vote-header-message">
              <p>第35屆亞洲小姐/先生競選</p>
              <p>（東南亞賽區）</p>
              <p>現場人氣獎</p>
            </div>
          )}

          <div className="live-vote-warning-message">
            現場呼聲最高就是大贏家？未必！你的一票，才是他的輸贏關鍵。趕緊點擊你心目中的人選，為他投票，讓他成為全場最受矚目的那位！
          </div>
        </section>
        <section className="live-vote-tab-wrapper">
          <div className={`live-vote-tab`}>
            <div
              className={`live-vote-tab-item ${
                this.state.tab === 0 ? "active" : ""
              }`}
              onClick={() => this.changeTab(0)}
            >
              <p>亞洲小姐</p>
            </div>
            <div
              className={`live-vote-tab-item ${
                this.state.tab === 0 ? "" : "active"
              }`}
              onClick={() => this.changeTab(1)}
            >
              <p>亞洲先生</p>
            </div>
          </div>
        </section>

        {this.state.votesRanking.miss &&
          this.state.votesRanking.mr &&
          MissPageantInfo &&
          MrPageantInfo && (
            <section className="bar-chart">
              <BarChart
                voteData={this.state.votesRanking.miss}
                title={`亚洲小姐 / Miss Pageant Asia`}
                pageant={MissPageantInfo}
                show={this.state.tab === 0}
              />
              <BarChart
                voteData={this.state.votesRanking.mr}
                title={`亚洲先生 / MR Pageant Asia`}
                pageant={MrPageantInfo}
                show={this.state.tab === 1}
              />
            </section>
          )}
        <PageantSection
          liveVoteMode={true}
          language={this.state.language}
          pageantInfo={this.state.tab === 0 ? MissPageantInfo : MrPageantInfo}
          showPageantCallback={(eachPageant) => {
            this.setState({
              showPageant: eachPageant,
            });
          }}
          pageantNaming={this.state.tab === 0 ? `#pageant-miss` : `#pageant-mr`}
        />
        {/* AUTO SCROLLER */}
        {/* <div className={`home-page-pageant-auto-scroller-wrapper ${"show"}`}>
          {this.state.tab === 0 ? (
            <div className="home-page-pageant-auto-scroller-container">
              {Object.keys(this.state.pageantMissAutoScroller).map(
                (eachKey, index) => {
                  return (
                    <div className={`bubble`} key={`auto-scroller-${eachKey}`}>
                      <a
                        className={`${
                          this.state.pageantMissAutoScroller[eachKey] === 1
                            ? "active"
                            : ""
                        }`}
                        href={`${`#pageant-miss`}-${eachKey}`}
                        onClick={() => {
                          // change url without refresh
                          setTimeout(() => {
                            window.history.pushState(
                              {},
                              "",
                              `${`#pageant-miss`}-${eachKey}`
                            );
                          }, 0.2);
                        }}
                      >
                        <div className="number">{eachKey}</div>
                      </a>
                    </div>
                  );
                }
              )}
            </div>
          ) : (
            <div className="home-page-pageant-auto-scroller-container">
              {Object.keys(this.state.pageantMrAutoScroller).map(
                (eachKey, index) => {
                  return (
                    <div className={`bubble`} key={`auto-scroller-${eachKey}`}>
                      <a
                        className={`${
                          this.state.pageantMrAutoScroller[eachKey] === 1
                            ? "active"
                            : ""
                        }`}
                        href={`${`#pageant-mr`}-${eachKey}`}
                        onClick={() => {
                          // change url without refresh
                          setTimeout(() => {
                            window.history.pushState(
                              {},
                              "",
                              `${`#pageant-mr`}-${eachKey}`
                            );
                          }, 0.2);
                        }}
                      >
                        <div className="number">{eachKey}</div>
                      </a>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div> */}

        <div
          className={`home-page-progressing-wrapper ${
            this.state.showMessageDialog ? "show" : ""
          }`}
        >
          <div className="home-page-progressing-container">
            <div className="backdrop">
              <svg
                width="156"
                height="136"
                viewBox="0 0 156 136"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M73.6701 2.49999C75.5946 -0.833342 80.4058 -0.833325 82.3303 2.50001L155.076 128.5C157.001 131.833 154.595 136 150.746 136H5.25402C1.40502 136 -1.00058 131.833 0.923917 128.5L73.6701 2.49999Z"
                  fill="#D9D9D9"
                />
                <path
                  d="M73.6701 2.49999C75.5946 -0.833342 80.4058 -0.833325 82.3303 2.50001L155.076 128.5C157.001 131.833 154.595 136 150.746 136H5.25402C1.40502 136 -1.00058 131.833 0.923917 128.5L73.6701 2.49999Z"
                  fill="url(#paint0_linear_0_1)"
                />
                <path
                  d="M77.9202 117.12C73.8402 117.12 70.7202 114 70.7202 110.08C70.7202 106.24 73.8402 102.96 77.9202 102.96C82.0002 102.96 85.2002 106.24 85.2002 110.08C85.2002 114 82.0002 117.12 77.9202 117.12ZM78.0002 54.48C82.0002 54.48 85.1202 57.12 85.1202 62.56C85.1202 66.4 84.4002 72.64 81.2002 86.96L79.2002 96.56H76.8002L74.8002 86.96C71.6002 72.56 70.8802 66.4 70.8802 62.56C70.8802 57.12 74.0002 54.48 78.0002 54.48Z"
                  fill="white"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_0_1"
                    x1="-117.05"
                    y1="-4.99995"
                    x2="117.69"
                    y2="230.222"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#D777B5" />
                    <stop offset="0.493567" stopColor="#C883DB" />
                    <stop offset="1" stopColor="#41B0D8" />
                  </linearGradient>
                </defs>
              </svg>

              <div
                className="close-btn"
                onClick={() => {
                  this.setState({
                    showMessageDialog: false,
                  });
                }}
              >
                <svg
                  width="60"
                  height="60"
                  viewBox="0 0 60 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="30" cy="30" r="30" fill="#F8F6F4" />
                  <path
                    d="M40.21 20.0629L19.9412 40.3316"
                    stroke="#dcac60"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M19.9385 20.0612L40.2072 40.3299"
                    stroke="#dcac60"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="message">
                {this.translate(this.state.message)}
              </div>

              {/* <button
                onClick={() => {
                  this.setState({
                    showMessageDialog: false,
                  });
                }}
                className="contestant-vote-btn vote-btn secondary">
                {this.translate("返回")}
              </button> */}
            </div>
          </div>
        </div>

        {/* <div
          style={{
            fontSize: 14,
            marginBottom: 16,
            opacity: 0.5,
            position: "absolute",
            bottom: 0,
          }}>
          Developed by Oaks Solution and Studio
        </div> */}
        <div
          style={{
            fontSize: 12,
            paddingBottom: 96,
            color: "#00000080",
            width: "100%",
          }}
        >
          {/*<p style={{ margin: "unset" }}>Developed by</p>
          <p style={{ margin: "unset" }}>Oaks Solution and Studio Sdn Bhd</p>
          <img src={OSASLogo} style={{ width: 32 }} alt="" loading="lazy" />*/}
          <p style={{ margin: "unset" }}>Copyright © 亞州電視</p>
        </div>
        <SuccessDialog
          pageant={this.state.resultPageant}
          voteCount={this.state.resultVoteCount}
          voterInfo={this.state.resultVoterInfo ?? {}}
          language={this.state.language}
          onClose={() => {
            this.setState({
              resultPageant: null,
            });
          }}
        />
        <BasicDialog
          show={this.state.votingTimeout}
          onClose={() => {
            this.setState({
              votingTimeout: false,
            });
          }}
          enTitle={`System Notice`}
          zhTitle={`系统通知`}
          enText={`The voting duration has ended, voting is disabled.`}
          zhText={`投票时间已结束，已无法投票`}
        />
        {this.state.showPageant != null ? (
          <VoteContestantDialog
            language={this.state.language}
            pointHistory={this.state.pointHistory}
            voteInfo={this.state.voteInfo}
            pageant={this.state.showPageant}
            onVote={this.performVote}
            onClose={() => {
              this.setState({
                showPageant: null,
              });
            }}
            onNextPrev={(direction) => {
              this.nextPrevPageant(direction);
            }}
            votingTimeout={this.setVotingTimeout}
          />
        ) : null}
      </div>
    );
  }
}

export default withRouter(LiveVote);
