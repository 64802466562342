import React, { Component } from "react";
import "./BarChart.scss";
import { getProperLanguage } from "../utilities/Utilities";

export default class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newVoteData: [],
      interval: 0,
      //   interval: 0,
      barContainerWidth: 0,
    };
  }

  translate(text) {
    if (this.props.language === "zh") {
      return text;
    } else {
      return getProperLanguage(text, true);
    }
  }

  calBarSize = (value) => {
    let barSize = 0;
    barSize = (value / 100) * this.state.barContainerWidth;
    // console.log(barSize);
    return `${barSize}px`;
  };

  calContainerSize = () => {
    const container = document.querySelector(".bar-list");
    this.setState({ barContainerWidth: container.offsetWidth }, () => {
      // console.log(this.state.barContainerWidth);
    });
  };

  findPageantNameWithId = (id) => {
    const { pageant } = this.props;
    const foundPageant = pageant.find(
      (pageant) => id === pageant.id.toString()
    );

    if (foundPageant) {
      // console.log(foundPageant.name_cn);
      return foundPageant.name_cn;
    }
    return "";
  };

  componentDidMount() {
    this.calContainerSize();
    window.addEventListener("resize", this.calContainerSize);

    if (this.props.voteData) {
      //console.log("voteData", this.props.voteData);
      let array = [];
      //   console.log(this.props.voteData);
      Object.entries(this.props.voteData).forEach(([key, value]) => {
        array.push({ id: key, votes: value });
      });
      const newVoteData = array.sort((a, b) => {
        return b.votes - a.votes;
      });

      this.setState({ newVoteData: newVoteData }, () => {
        // console.log(newVoteData, "Sorted Array");
      });
    }
  }

  componentWillUnmount() {
    // Remove event listener to prevent memory leaks
    window.removeEventListener("resize", this.calContainerSize);
  }

  render() {
    const { show, title } = this.props;
    return (
      <div
        className={` ${
          show
            ? "bar-chart-wrapper show-bar-chart-wrapper"
            : "bar-chart-wrapper"
        }`}
      >
        <div className="bar-chart-title">{this.translate(title)}</div>
        <div className="bar-chart-container">
          <div className="contestant-name-wrapper">
            {this.state.newVoteData.map((contestant, index) => {
              return (
                <div key={index} className="contestant-name">
                  <p>
                    {contestant.id === "others" ? (
                      "Others"
                    ) : (
                      <>
                        #{contestant.id}{" "}
                        {this.state.barContainerWidth >= 512 && (
                          <>{this.findPageantNameWithId(contestant.id)}</>
                        )}
                      </>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
          <div className="bar-list">
            {this.state.newVoteData.map((contestant, index) => {
              return (
                <div
                  key={`contestant-${index}`}
                  className="bar"
                  style={{ maxWidth: this.calBarSize(contestant.votes) }}
                >
                  <p
                    className={`${
                      contestant.votes < 50 ? "floating-text" : ""
                    }`}
                  >
                    {contestant.votes}%
                  </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="bar-chart-container">
          <div></div>
          <div className="vote-interval">
            <p>0</p>
            <p>100%</p>
          </div>
        </div>
      </div>
    );
  }
}
