import React, { Component } from "react";
import "./VotingSelectionButton.scss";
import { getProperLanguage } from "../utilities/Utilities";

export default class VotingSelectionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
  }

  translate(text) {
    if (this.props.language === "zh") {
      return text;
    } else {
      return getProperLanguage(text, true);
    }
  }

  render() {
    const { selectedVoting, vote } = this.props;
    return (
      <div
        className={`voting-selection-button ${
          selectedVoting.id === vote.id ? `selected` : ""
        }`}
        onClick={() => this.props.onClickCallback(vote)}
      >
        {this.props.vote.val}
        {this.translate("票/Ticket")} - RM{vote.price}
      </div>
    );
  }
}
