const APIPaths = {
  // apiEndPoint: "https://atvnewsonline.com/wp-json/apiapp/v1/",
  getPointHistory: "user/get_point_history",
  getVoteInfo: "pageant/get_vote_info",
  vote: "pageant/vote",
  privateVote: "pageant/vote_pageant_private",
  privateVoteURL: "https://atvnewsonline.com/app/live_vote/get_vote_data.php",

  //new api
  apiEndPoint: "https://livevote.atvnewsonline.com/",
  getVotesRanking: "api/p/rank.php",
  createOrder: "payment/create.php",
};

export default APIPaths;
