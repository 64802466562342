import React, { Component } from "react";
import "./VoteContestantDialog.scss";
import PropTypes from "prop-types";
import FadeLoadImage from "../components/FadeLoadImage";
import {
  base64ToImage,
  getProperLanguage,
  isValidEmail,
  stretchNumber,
} from "../utilities/Utilities";
import {
  DefaultPageant,
  DefaultPointHistory,
  DefaultVoteInfo,
  countryCodes,
} from "../configs/Default";
import ReactGA from "react-ga4";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import VotingSelectionButton from "../components/VotingSelectionButton";
import { createOrder } from "../utilities/Requests";
import APIResult from "../configs/APIResult";

class VoteContestantDialog extends Component {
  constructor(props) {
    super(props);

    this.minVote = 20;

    this.state = {
      confirmationMode: false,
      inputVote: this.minVote,
      swipeMode: "",
      time: 0,
      captchaRound: 0,
      captchaVerified: false,

      //new stuff
      voter_uid: "",
      invalid_voter_uid: false,
      table_no: "",
      invalid_table_no: false,
      phone_number: "60",
      invalid_phone_number: false,
      selected_voting: {},
      voting_selections: [
        { id: 1, val: 100, price: "99.90" },
        { id: 2, val: 500, price: "199.90" },
        { id: 3, val: 2000, price: "499.90" },
        { id: 4, val: 6000, price: "999.90" },
        { id: 5, val: 15000, price: "1999.90" },
      ],
      name: "",
      email: "",
    };

    this.updateInputVote = this.updateInputVote.bind(this);
    this.getTotalVoteCount = this.getTotalVoteCount.bind(this);
    this.isPointSufficient = this.isPointSufficient.bind(this);
    this.translate = this.translate.bind(this);
    this.confirmVoteCount = this.confirmVoteCount.bind(this);
    this.performVote = this.performVote.bind(this);
    this.focusDivRef = React.createRef();
  }

  sendOrder = async () => {
    console.log("Ordering...");
    // console.log(this.props.pageant, "Pageant Info");

    const obj = {
      v_id: this.props.pageant.id,
      v_gd: this.props.pageant.gd,
      votes: this.state.selected_voting.val,
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone_number,
    };
    console.log(obj);
    let result = await createOrder(obj);
    console.log(result);

    if (result.status === APIResult.INVALID) {
      // window.location.reload();
      this.props.votingTimeout(true);
    }

    if (result.status === APIResult.SUCCESS) {
      console.log(result);
      window.location.href = result.redirect;
    }
  };

  componentDidMount() {
    // console.log(this.props);
    // console.log(this.props.pageant, "Pageant Info");
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed and inputSelected is not an empty object
    if (
      prevState.selected_voting !== this.state.selected_voting &&
      Object.keys(this.state.selected_voting).length !== 0
    ) {
      this.scrollToDiv();
    }
  }

  scrollToDiv = () => {
    if (this.focusDivRef.current) {
      this.focusDivRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  //check for invlaid_voter_uid when theres less than 5 characters in the input

  updateName = (e) => {
    //e.target.value = e.target.value.replace(/[^a-zA-Z ]/g, "");
    this.setState({ name: e.target.value });
  };

  updateEmail = (e) => {
    //check if email is valid
    //e.target.value = e.target.value.replace(/[^a-zA-Z0-9@.]/g, "");
    this.setState({ email: e.target.value });
  };

  updateVoterUID = (e) => {
    this.setState({ voter_uid: e.target.value });
    if (e.target.value.length < 5) {
      this.setState({ invalid_voter_uid: true });
    } else {
      this.setState({ invalid_voter_uid: false });
    }
  };

  updateTableNo = (e) => {
    //dont allow negative numbers
    // remove all non-digit characters
    // if the length is less than 1 or more than 4, set the invalid_table_no to true
    // else set the invalid_table_no to false
    var value = e.target.value;
    value = value.replace(/\D/g, "");

    if (value < 0) {
      this.setState({ table_no: 0 });
    } else {
      this.setState({ table_no: value });
    }
    const tableNo = Number(value ?? 0);
    if (tableNo < 1 || (tableNo > 172 && tableNo !== 888)) {
      this.setState({ invalid_table_no: true });
    } else {
      this.setState({ invalid_table_no: false });
    }
  };

  updatePhoneNumber = (e) => {
    let inputValue = e.target.value;

    // Remove all non-digit characters (including hyphens) except plus sign
    const sanitizedValue = inputValue.replace(/\D/g, "");

    //check first digit or first 2 digits or first 3 digits to see if it matches any of the country codes, if any of it is true, then set the phone number to the sanitized value if not then set the invalid phone number to true
    let firstDigit = sanitizedValue.substring(0, 1);
    let firstTwoDigits = sanitizedValue.substring(0, 2);
    let firstThreeDigits = sanitizedValue.substring(0, 3);

    let countryCodeMatch = false;

    countryCodes.forEach((countryCode) => {
      if (
        firstDigit === countryCode ||
        firstTwoDigits === countryCode ||
        firstThreeDigits === countryCode
      ) {
        countryCodeMatch = true;
      }
    });

    if (!countryCodeMatch) {
      this.setState({
        phone_number: sanitizedValue,
        invalid_phone_number: true,
      });
    }

    if (countryCodeMatch) {
      // Check if the phone number length is within the acceptable range
      if (sanitizedValue.length < 10 || sanitizedValue.length > 14) {
        this.setState({
          phone_number: sanitizedValue,
          invalid_phone_number: true,
        });
      } else {
        // find the matching country code and set the phone number to the this format 6010 1234 567
        let formattedPhoneNumber = "";

        let countryCodeSize = 0;

        countryCodes.forEach((countryCode) => {
          if (firstThreeDigits === countryCode) {
            countryCodeSize = 3;
          }

          if (firstTwoDigits === countryCode) {
            countryCodeSize = 2;
          }

          if (firstDigit === countryCode) {
            countryCodeSize = 1;
          }
        });

        formattedPhoneNumber =
          sanitizedValue.substring(0, countryCodeSize) +
          " " +
          sanitizedValue.substring(countryCodeSize, sanitizedValue.length);

        this.setState({
          phone_number: formattedPhoneNumber,
          invalid_phone_number: false,
        });
      }
    }
  };

  updateInputVote(delta) {
    this.setState({
      inputVote: Math.min(
        Math.max(Number(this.state.inputVote) + delta, this.minVote),
        99999
      ),
    });
  }

  isCaptchaVerified() {
    // captcha is will not be required during 16:45 - 17:00 GMT+8 everyday
    if (!this.showCaptcha()) return true;

    return this.state.captchaVerified;
  }

  showCaptcha() {
    const now = new Date();
    const hour = now.getHours();
    const minute = now.getMinutes();
    if (hour === 16 && minute >= 45) return false;
    if (hour === 17 && minute <= 0) return false;
    return true;
  }

  getTotalVoteCount() {
    const voteInfo = this.props.voteInfo;
    const pageant = this.props.pageant;
    let totalVote = 0;
    if (pageant === null || pageant === undefined) return 0;
    if (voteInfo === null || voteInfo === undefined) return 0;
    if (voteInfo.vote_history === null || voteInfo.vote_history === undefined)
      return 0;
    voteInfo.vote_history.forEach((vote) => {
      if (Number(vote.pageant_id) === Number(this.props.pageant.id ?? 0)) {
        totalVote += Number(vote.vote_count);
      }
    });
    return totalVote;
  }

  isPointSufficient() {
    const pointHistory = this.props.pointHistory;
    return this.getProperInputVote() <= Number(pointHistory.point ?? 0);
  }

  getProperInputVote() {
    return Math.max(Number(this.state.inputVote), this.minVote);
  }

  translate(text) {
    if (this.props.language === "zh") {
      return text;
    } else {
      return getProperLanguage(text, true);
    }
  }

  confirmVoteCount() {
    ReactGA.event({
      category: "confirm_vote",
      action: "pageant",
      label: this.props.pageant.name_cn,
      value: Number(this.getProperInputVote()),
    });
    this.setState({
      confirmationMode: true,
    });
  }

  performVote() {
    this.props.onVote(
      this.state.phone_number,
      this.props.pageant.id,
      this.state.table_no
    );
  }
  ableToVote() {
    if (
      Object.keys(this.state.selected_voting).length > 0 &&
      this.state.name &&
      this.state.email &&
      this.state.phone_number.length > 7
    ) {
      if (isValidEmail(this.state.email)) return true;
    }
    return false;
  }

  selectVotingCallback = (obj) => {
    // const value = index + 1;
    this.setState(
      {
        selected_voting: obj,
      },
      () => {
        // console.log(this.state.selected_voting);
      }
    );
  };

  render() {
    const language = this.props.language ?? "zh";
    const pageant = this.props.pageant ?? DefaultPageant;
    return (
      <div
        className={`vote-contestant-dialog-wrapper ${
          this.props.pageant !== null && "enable"
        }`}
      >
        <div className="vote-contestant-dialog-container">
          <svg
            style={{ pointerEvents: "none", opacity: 0 }}
            onClick={() => {
              this.props.onNextPrev(-1);
              this.setState({
                swipeMode: "prev",
                time: this.state.time + 1,
              });
            }}
            className={`left arrow ${
              this.state.confirmationMode ? "hide" : ""
            }`}
            viewBox="0 0 71 71"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="35.3551"
              cy="35.3554"
              r="35.3553"
              transform="rotate(180 35.3551 35.3554)"
              fill="#dcac60"
            />
            <path
              d="M32.8197 42.7107L24.7104 34.6014M24.7104 34.6014L32.8197 26.4922M24.7104 34.6014L44.7702 34.6014"
              stroke="#F8F6F4"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_80_2149"
                x1="-38.0072"
                y1="3.26754e-05"
                x2="50.2832"
                y2="88.4719"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#D777B5" />
                <stop offset="0.493567" stopColor="#C883DB" />
                <stop offset="1" stopColor="#41B0D8" />
              </linearGradient>
            </defs>
          </svg>
          <div className="main">
            <div className="backdrop-edge">
              <div className="backdrop">
                <div className="top-bar">
                  <div className="close-btn"></div>
                  <div className="vote-contestant-dialog-title-wrapper">
                    <div
                      className={`vote-contestant-dialog-title ${
                        language === "zh" ? "" : "sc"
                      }`}
                    >
                      <p>{this.translate("投票專區")}</p>
                    </div>
                  </div>
                  <div
                    className="close-btn"
                    onClick={() => {
                      this.props.onClose();
                      setTimeout(() => {
                        this.setState({
                          confirmationMode: false,
                        });
                      });
                    }}
                  >
                    <svg
                      width="60"
                      height="60"
                      viewBox="0 0 60 60"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="30" cy="30" r="30" fill="#F8F6F4" />
                      <path
                        d="M40.21 20.0629L19.9412 40.3316"
                        stroke="#dcac60"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M19.9385 20.0612L40.2072 40.3299"
                        stroke="#dcac60"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className={`vote-container`} key={this.state.time}>
                  <div className="profile-picture-wrapper">
                    <div
                      className={`profile-picture-container ${this.state.swipeMode}`}
                    >
                      <FadeLoadImage
                        preloadThumb={base64ToImage(
                          pageant.profile_picture_b64
                        )}
                        fullSize={pageant.profile_picture}
                        class="tone-up hover-zoom"
                        style={{
                          objectFit: "cover",
                          aspectRatio: 0.75,
                          borderRadius: 20,
                          objectPosition: "top",
                        }}
                        wrapperStyle={{
                          justifyContent: "flex-start",
                          // maxHeight: "50vh",
                          borderRadius: 20,
                          background:
                            "linear-gradient(167deg, #dcac60 0%, #8e3798 50%, #358fe1 100%)",
                        }}
                      />
                      <div className="contestant_id">
                        {stretchNumber(pageant.id, 2)}
                      </div>
                    </div>
                  </div>
                  <div className="vote-zone-container">
                    <div className="vote-zone">
                      {/* Namings */}
                      <div className="vote-chant">
                        <div
                          style={{
                            border: "none",
                            padding: 0,
                            paddingBottom: 0,
                          }}
                          className={`vote-chant ${
                            language === "zh" ? "" : "sc"
                          }`}
                        >
                          {this.translate("為 ")}
                          <span className="name_cn">
                            {this.translate(pageant.name_cn)}
                          </span>

                          {this.translate(" 投票")}
                        </div>

                        <div
                          style={{
                            border: "none",
                            fontSize: "12px",
                            letterSpacing: "1px",
                          }}
                          className={`vote-chant ${
                            language === "zh" ? "" : "sc"
                          }`}
                        >
                          {"Vote For "}
                          <span
                            style={{ border: "none", fontSize: "14px" }}
                            className={`name_en ${
                              pageant.name_en.length > 15 ? "small" : ""
                            }`}
                          >
                            {pageant.name_en}
                          </span>
                        </div>
                      </div>

                      {/* Voting Buttons */}
                      <div className="voting-button-list">
                        {this.state.voting_selections.map((vote, index) => {
                          return (
                            <VotingSelectionButton
                              key={index}
                              selectedVoting={this.state.selected_voting}
                              vote={vote}
                              language={this.props.language}
                              onClickCallback={this.selectVotingCallback}
                            />
                          );
                        })}
                      </div>

                      <div
                        className="voting-details-container"
                        style={{ marginBottom: "0.6rem" }}
                        ref={this.focusDivRef}
                      >
                        <TextField
                          label={this.translate("姓名/ Name")}
                          fullWidth={true}
                          size="small"
                          type="text"
                          onChange={this.updateName}
                          value={this.state.name}
                          className="input-sizing"
                        />
                        <TextField
                          label={this.translate("電郵/ Email")}
                          fullWidth={true}
                          size="small"
                          //pattern="[a-zA-Z]*"
                          onChange={this.updateEmail}
                          value={this.state.email}
                        />
                        <TextField
                          label={this.translate("您的電話號碼/ Phone No.")}
                          fullWidth={true}
                          size="small"
                          error={this.state.invalid_phone_number}
                          onChange={this.updatePhoneNumber}
                          value={this.state.phone_number}
                          helperText={
                            <>
                              <div style={{ fontSize: "10px" }}>
                                範例 / e.g.
                              </div>

                              <div style={{ fontSize: "10px" }}>
                                +60 1012345678（馬來西亞/ Malaysia）
                              </div>
                              <div style={{ fontSize: "10px" }}>
                                +65 0123456789（新加坡/ Singapore）
                              </div>
                            </>
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>

                      <div id="last">
                        <div
                          style={{
                            filter: !this.ableToVote()
                              ? "grayscale(100%)"
                              : "drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.125))",
                            pointerEvents: !this.ableToVote() ? "none" : "auto",
                          }}
                          // onClick={this.performVote}
                          onClick={this.sendOrder}
                          className="contestant-vote-btn"
                        >
                          {this.translate(" 投票/ Vote")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

VoteContestantDialog.propTypes = {
  pageant: PropTypes.object,
  onClose: PropTypes.func,
  onNextPrev: PropTypes.func,
  pointHistory: PropTypes.object,
  voteInfo: PropTypes.object,
  onVote: PropTypes.func,
};

VoteContestantDialog.defaultProps = {
  pageant: null,
  onClose: () => {},
  onNextPrev: () => {},
  pointHistory: DefaultPointHistory,
  voteInfo: DefaultVoteInfo,
  onVote: () => {},
};

export default VoteContestantDialog;
