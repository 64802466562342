import APIHeader from "../configs/APIHeader";
import APIPaths from "../configs/APIPaths";
import APIResult from "../configs/APIResult";

// import { debugPrint } from "./Utilities";

async function callAPI(body, target, defaultReturn, method = "POST") {
  let requestOptions = {};
  if (method === "GET") {
    requestOptions = {
      method: method,
      // credentials: "include",
      headers: {},
    };
  }

  if (method === "POST") {
    requestOptions = {
      method: method,
      // credentials: "include",
      headers: {
        "Content-Type": APIHeader.contentType,
        // "X-WP-Nonce": window.nonce,
        // appapikey: "D9eJYSgI$!iB6DwexW9&XL0b",
        // appapikey: "uhri&8@.33uwi",
      },
      body,
    };
  }

  if (method !== "GET" && body) {
    requestOptions.body = JSON.stringify(body);
  }
  // console.log(requestOptions);

  // debugPrint(target);
  // debugPrint(body);

  var proceed = true;

  if (proceed) {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return await fetch(target, requestOptions)
      .then((response) => {
        // console.log(response);
        return response.json();
      })
      .then((responseData) => {
        return responseData;
      })
      .catch((error) => {
        // console.log(error);
        return defaultReturn;
      });
  } else {
    return defaultReturn;
  }
}

export async function getPointHistory() {
  var body = {};
  var target = APIPaths.apiEndPoint + APIPaths.getPointHistory;

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    result: [],
  });
}

export async function getVoterInfo() {
  var body = {};
  var target = APIPaths.apiEndPoint + APIPaths.getVoteInfo;

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    result: [],
  });
}

export async function votePageant(pageantID, voteCount, freeVote) {
  var body = {
    pageant_id: pageantID,
    vote_count: Number(voteCount),
    free_vote: freeVote ? 1 : 0,
  };
  var target = APIPaths.apiEndPoint + APIPaths.vote;

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    result: [],
  });
}

export async function votePageantPrivate(
  phoneNumber,
  pageantID,
  tableNo,
  browserKey,
  wpUid
) {
  var body = {
    phone_number: phoneNumber,
    pageant_id: pageantID,
    table_no: tableNo,
    browser_key: browserKey,
    wp_uid: wpUid,
  };
  var target = APIPaths.privateVoteURL;

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    result: [],
  });
}

export async function getVotesRanking() {
  var body = {};
  var target = APIPaths.apiEndPoint + APIPaths.getVotesRanking;
  // console.log(target);
  return callAPI(
    body,
    target,
    {
      status: APIResult.TIME_OUT,
      status_message: "-",
      result: [],
    },
    "GET"
  );
}

export async function createOrder(buyerData) {
  var body = {
    buy_data: {
      v_id: buyerData.v_id,
      v_gd: buyerData.v_gd,
      votes: buyerData.votes,
      name: buyerData.name,
      email: buyerData.email,
      phone: buyerData.phone,
    },
  };
  var target = APIPaths.apiEndPoint + APIPaths.createOrder;
  // console.log(body, target);

  return callAPI(body, target, {
    status: APIResult.TIME_OUT,
    status_message: "-",
    result: [],
  });
}
