import zhConvertor from "zhconvertor";

export function debugPrint(...args) {
  if (isDebug) {
    console.log(...args);
  }
}

export function isDebug() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "development";
}

export function base64ToImage(base64) {
  return "data:image/png;base64," + base64;
}

export function getProperLanguage(str, simplified) {
  if (simplified) {
    return zhConvertor.t2s(str);
  } else {
    return str;
  }
}

export function commafy(num) {
  if (isNaN(num)) {
    return "0";
  }
  // check if num is a number

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function randomBetween(min, max) {
  let multiplier = Math.random();
  let baseValue = Math.floor(multiplier * (max - min + 1) + min);
  // skew return value, if multiplier is low, it'll be a lot closer to min, and vice versa
  return Math.floor(baseValue * multiplier);
}

export function stretchNumber(num, length) {
  let numStr = num.toString();
  while (numStr.length < length) {
    numStr = "0" + numStr;
  }
  return numStr;
}

export function numberConvertion(n) {
  // convert number from to chinese number
  // 1 -> 一
  // 10 -> 十
  // 11 -> 十一
  // 20 -> 二十
  // 21 -> 二十一
  // 100 -> 一百
  // 101 -> 一百零一
  // 110 -> 一百一十

  if (!Number.isInteger(n) && n < 0) {
    throw Error("请输入自然数");
  }

  const digits = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"];
  const positions = [
    "",
    "十",
    "百",
    "千",
    "万",
    "十万",
    "百万",
    "千万",
    "亿",
    "十亿",
    "百亿",
    "千亿",
  ];
  const charArray = String(n).split("");
  let result = "";
  let prevIsZero = false;
  //处理0  deal zero
  for (let i = 0; i < charArray.length; i++) {
    const ch = charArray[i];
    if (ch !== "0" && !prevIsZero) {
      result += digits[parseInt(ch)] + positions[charArray.length - i - 1];
    } else if (ch === "0") {
      prevIsZero = true;
    } else if (ch !== "0" && prevIsZero) {
      result +=
        "零" + digits[parseInt(ch)] + positions[charArray.length - i - 1];
    }
  }
  //处理十 deal ten
  if (n < 100) {
    result = result.replace("一十", "十");
  }
  return result;
}
export function browserKeyGenerator() {
  //random 8 characters make it unique
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < 8; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function isValidEmail(email) {
  // Regular expression for validating email format
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  // Check if the email is a non-empty string and matches the regex
  if (typeof email === "string" && email.length > 0 && emailRegex.test(email)) {
    return true;
  } else {
    return false;
  }
}
