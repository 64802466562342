import React, { Component } from "react";
import "./FadeLoadImage.scss";
import PropTypes from "prop-types";

class FadeLoadImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {}

  render() {
    let preloadThumbStyle = { ...this.props.style };
    let fullSizeStyle = { ...this.props.style };
    preloadThumbStyle.opacity = this.state.loaded ? 0 : 1;
    preloadThumbStyle.display = this.state.loaded ? "none" : "block";
    fullSizeStyle.opacity = this.state.loaded ? 1 : 0;
    let maskStyle = {
      WebkitMaskImage: `url(${this.props.mask})`,
      maskImage: `url(${this.props.mask})`,
    };
    if (this.props.wrapperStyle) {
      maskStyle = { ...maskStyle, ...this.props.wrapperStyle };
    }
    return (
      <div
        className="fade-load-image-wrapper"
        style={this.props.mask !== "" ? maskStyle : this.props.wrapperStyle}>
        <img
          draggable={false}
          className={`full ${this.props.class ?? ""}`}
          alt="full"
          loading="lazy"
          onLoad={() => {
            this.setState({
              loaded: true,
            });
          }}
          src={this.props.fullSize}
          style={fullSizeStyle}
        />
        <img
          alt="preload"
          className="preload"
          loading="lazy"
          src={this.props.preloadThumb}
          style={preloadThumbStyle}
        />
      </div>
    );
  }
}

FadeLoadImage.propTypes = {
  preloadThumb: PropTypes.string,
  fullSize: PropTypes.string,
  mask: PropTypes.string,
  style: PropTypes.object,
  wrapperStyle: PropTypes.object,
};

FadeLoadImage.defaultProps = {
  preloadThumb: "",
  fullSize: "",
  mask: "",
  style: {},
  wrapperStyle: {},
};

export default FadeLoadImage;
